import React from "react";
import { withRouter } from 'react-router-dom';
import { Grid, Card, CardHeader, Container, Box } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Slider from '@material-ui/core/Slider';
import SaveIcon from '@material-ui/icons/Save';
import SyncIcon from '@material-ui/icons/Sync';
import { generate_img, exportPDF } from "../controller/MagneticAction";
import Avatar from '@material-ui/core/Avatar';
import CardActions from '@material-ui/core/CardActions';
import applicationLogo from '../../../design/zeiss-logo-tagline_rgb.png';
import InputLabel from "@material-ui/core/InputLabel/InputLabel";
import Select from "@material-ui/core/Select/Select";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Feedback } from "@material-ui/icons";


const styles = theme => ({
    inpage: {
        width: "1024px",
        margin: "auto",
    },
    margin: {
        margin: theme.spacing(1),
    },
    slider: {
        margin: theme.spacing(3),
        // width: "60%",
        height: 8,
    },
    root: {
        flexGrow: 1,
    },
    media: {
        width: '1024px',
        height: 0,
        marginLeft: "auto",
        marginRight: "auto",
        paddingTop: '56.25%', // 16:9
    },
    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    large: {

        backgroundColor: 'white',
        width: theme.spacing(10),
        height: theme.spacing(10),
    },
});

function valuetext(value) {
    return `${value}`;
}

const Magnification = withStyles({
    root: {
        // color: '#464a47',
        height: 6,
    },
    thumb: {
        height: 27,
        width: 27,
        backgroundColor: '#fff',
        border: '1px solid currentColor',
        marginTop: -12,
        marginLeft: -13,
        boxShadow: '#ebebeb 0 2px 2px',
        '&:focus, &:hover, &$active': {
            boxShadow: '#ccc 0 2px 3px 1px',
        },
    },
    active: {},
    valueLabel: {
        left: 'calc(-50% + 4px)',
    },
    track: {
        height: 8,
        borderRadius: 4,
    },
    rail: {
        height: 8,
        borderRadius: 4,
    },
})(Slider);

class VerticalSlider extends React.Component {
    constructor(props) {
        super(props);
        this.refreshImage = this.refreshImage.bind(this);
        this.goBacktoInput = this.goBacktoInput.bind(this);
        this.exportFile = this.exportFile.bind(this);

        this.state = {
            min_value: 0,
            max_value: 0,
            marks: [],
            pic: "",
            value: 0,
            index: 4,
            input_data: { system: "", energy: "", distance: "", X: { "distortion_value": "" }, Y: { "distortion_value": "" }, Z: { "distortion_value": "" } },
            response_data: {},
            selectOptionsEnergy: [],
            selectOptionsDistance: [],
            beamEnergy: "",
            workingDistance: "",
            overhead: "",
            loadingScreen: false,
            customer_name: "",
            option_inputs: [],
            refreshingImage: false
        };
    }

    componentDidMount() {
        // Load option beam energy
        let dataInput = JSON.parse(localStorage.getItem("dataInput"))
        let option_inputs = this.props.location.state
        let copyArrayEnergy = [];
        for (let key in option_inputs) {
            if (option_inputs.hasOwnProperty(key)) {
                copyArrayEnergy.push(<MenuItem key={key} id={key} value={key}>{key}</MenuItem>)
            }
        }
        // Load option "working distance" for energy selected
        let energy_selected = dataInput["energy"];
        let distance_selected = dataInput["distance"];
        let customer_name = dataInput["customer_name"]
        let option_distance = option_inputs[energy_selected];
        let copyArrayDistance = [];
        for (let key in option_distance) {
            if (option_distance.hasOwnProperty(key)) {
                copyArrayDistance.push(<MenuItem key={option_distance[key]} id={option_distance[key]}
                    value={option_distance[key]}>{option_distance[key]}</MenuItem>)
            }
        }

        this.setState({
            selectOptionsEnergy: copyArrayEnergy,
            selectOptionsDistance: copyArrayDistance,
            beamEnergy: energy_selected,
            workingDistance: distance_selected,
            customer_name: customer_name
        });

        generate_img(dataInput).then(({ data: response_url }) => {
            if ('redirect' in response_url) {
                this.props.history.push('/login')
            }

            this.setState({
                pic: response_url["imageBytesDistortion"],
                marks: response_url["steps"],
                max_value: response_url["steps"][response_url["steps"].length - 1].value,
                min_value: response_url["steps"][0].value,
                input_data: JSON.parse(localStorage.getItem("dataInput")),
                response_data: response_url,
                overhead: response_url["overhead"],
                value: 0,
                option_inputs: option_inputs
            });
        });

    }

    updateValue = (event, newValue) => {
        if (newValue !== this.state.value) {
            this.setState({ value: newValue });
            let dataInput = JSON.parse(localStorage.getItem("dataInput"));
            let magnification = parseFloat(this.state.response_data['steps'][newValue]['label'].slice(0, -1)) * 1000
            dataInput.magnification = magnification;
            const json = JSON.stringify(dataInput);
            localStorage.setItem("dataInput", json);
        }
    };

    refreshImage = async () => {
        await this.setState({refreshingImage:true})
        let dataInput = JSON.parse(localStorage.getItem("dataInput"));
        dataInput.energy = this.state.beamEnergy;
        dataInput.distance = this.state.workingDistance;
        dataInput.overhead = this.state.overhead;

        const json = JSON.stringify(dataInput);
        localStorage.setItem("dataInput", json);
        generate_img(dataInput).then(({ data: response_url }) => {
            if ('redirect' in response_url) {
                this.props.history.push('/login')
            }
            this.setState({
                pic: response_url["imageBytesDistortion"],
                input_data: JSON.parse(localStorage.getItem("dataInput")),
                response_data: response_url,
                refreshingImage: false
            });
        });
    }
    goBacktoInput() {
        // this.props.history.push('/input')
        this.props.history.push({
            pathname: '/inputs',
            state: { goBack: "yes" }
        })
    }
    exportFile() {
        this.setState({ loadingScreen: true })
        let dataInput = JSON.parse(localStorage.getItem("dataInput"));
        dataInput.energy = this.state.beamEnergy;
        dataInput.distance = this.state.workingDistance;
        dataInput.overhead = this.state.overhead;
        dataInput.magnification = this.state.response_data["magnification"] + "k"
        dataInput.totalAcField = Math.max(parseFloat(this.state.input_data["X"]["distortion_value"]), parseFloat(this.state.input_data["Y"]["distortion_value"]), parseFloat(this.state.input_data["Z"]["distortion_value"])) * this.state.response_data["overhead"] + " mG"
        dataInput.imageSize = "1024px X 1024px"
        dataInput.pixelSize = this.state.response_data["pixel_size"]
        //        dataInput.distortion = this.state.response_data["distortion"] + " nm"
        dataInput.angle = this.state.response_data["angle"] === 90 ? "Y Axis" : "X Axis";
        dataInput.ImageBytes = this.state.pic


        exportPDF(dataInput).then(({ data: response_url }) => {
            this.setState({ loadingScreen: false })
            if ('redirect' in response_url) {
                this.props.history.push('/login')
            } else {
                let link = document.createElement('a');
                const mimeType = "application/pdf";
                link.href = `data:${mimeType};base64,${response_url["data"]}`;
                link.download = response_url["download_name"];
                link.click();
            }
        });

    }

    render() {
        const { classes } = this.props;

        const handleChangeBeamEnergy = (event) => {
            let option_distance = this.state.option_inputs[event.target.value];
            let copyArrayDistance = [];
            for (let key in option_distance) {
                if (option_distance.hasOwnProperty(key)) {
                    copyArrayDistance.push(<MenuItem key={option_distance[key]} id={option_distance[key]}
                        value={option_distance[key]}>{option_distance[key]}</MenuItem>)
                }
            }
            this.setState({
                beamEnergy: event.target.value,
                workingDistance: '',
                selectOptionsDistance: copyArrayDistance
            });
        };
        const handleChangeWorkingDistance = (event) => {
            this.setState({ workingDistance: event.target.value });
        };
        const handleChangeRadioButtons = (event) => {
            this.setState({ overhead: event.target.value });

        };
        const theme = {
            fontSize: 12.5,
        };

        return <>
            {this.state.pic === "" || this.state.loadingScreen === true ?  <div
                style={{
                    top: "50%",
                    left: "50%",
                    marginTop: "-50px",
                    marginLeft: "-100px",
                    position: "absolute",
                    width:"200px",
                    height:"200px"
                }}
              >
                
                <img alt="App logo" src={applicationLogo} style={{
                    width: "80%",
                    height:"80%",
                    position:"absolute",
                    left:"10%",
                    top:"5%"
                }} />

                    <CircularProgress style={{
                        position: "absolute",
                        width:"100%",
                        height:"100%"
                    }} />

            </div> :
                <Container>
                    <Grid className="page_header" style={{ marginBottom: 20 }}>
                        <Grid container direction="row" justifyContent="center" alignItems="center">
                            <Grid item xs={12} md={12} lg={12} xl={12}>
                                <Grid container direction="row">
                                    <CardHeader
                                        avatar={
                                            <Avatar variant={"square"} aria-label="ripple-simulator"
                                                src={applicationLogo}
                                                className={classes.large} />
                                        }
                                        titleTypographyProps={{ variant: "h4", color: 'textPrimary' }}
                                        title={"Ripple Simulator - Magnetic distortion simulation"}
                                        subheaderTypographyProps={{ variant: "h5", color: 'textSecondary' }}
                                        subheader={this.state.customer_name !== undefined ? "Customer name: " + this.state.customer_name : null}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container direction="column" spacing={1} justifyContent="center" alignItems="center">
                        <Grid item xs={12} style={{ paddingBottom: 10 }}>
                            <Grid container direction="row">
                                <Grid item lg={3} xs={6}>
                                    <Typography style={theme}><b>Input parameters</b></Typography>
                                    <Typography style={theme}>SYSTEM PARAMETERS:</Typography>
                                    <Typography style={theme}>System name: {this.state.input_data["system"]}</Typography>
                                    <Typography style={theme}>Beam energy: {this.state.input_data["energy"]}</Typography>
                                    <Typography style={theme}>Working distance: {this.state.input_data["distance"]}</Typography>
                                    <Typography style={theme}>Magnification: {this.state.response_data["magnification"] + "k"}</Typography>
                                </Grid>
                                <Grid item lg={3} xs={6}>
                                    <Typography style={theme}>MEASURED AC FIELD:</Typography>
                                    {(this.state.input_data["X"] !== undefined && this.state.input_data["X"]["distortion_value"] != "") &&
                                        (<Typography style={theme}>X: {this.state.input_data["X"]["distortion_value"] + " mG, @ " +
                                            this.state.input_data["X"]["frequency"] + " Hz"}</Typography>)}
                                    {(this.state.input_data["Y"] !== undefined && this.state.input_data["Y"]["distortion_value"] != "") &&
                                        (<Typography style={theme}>Y: {this.state.input_data["Y"]["distortion_value"] + " mG, @ " +
                                            this.state.input_data["Y"]["frequency"] + " Hz"}</Typography>)}
                                    {(this.state.input_data["Z"] !== undefined && this.state.input_data["Z"]["distortion_value"] != "") &&
                                        (<Typography style={theme}>Z: {this.state.input_data["Z"]["distortion_value"] + " mG, @ " +
                                            this.state.input_data["Z"]["frequency"] + " Hz"}</Typography>)}
                                    <Typography style={theme}>Deviation from measured: {(this.state.response_data["overhead"] - 1) * 100 !== 0 ? Math.floor(this.state.response_data["overhead"] * 100) - 100 + "%" : "None"}</Typography>
                                    <Typography style={theme}>Simulation input parameters used: {(Math.max(parseFloat(this.state.input_data["X"]["distortion_value"]), parseFloat(this.state.input_data["Y"]["distortion_value"]), parseFloat(this.state.input_data["Z"]["distortion_value"])) * this.state.response_data["overhead"]).toFixed(2)} mG</Typography>
                                </Grid>
                                <Grid item lg={3} xs={6}><Typography style={theme}><b>Image details</b></Typography>
                                    <Typography style={theme}>Image size: 1024px X 1024px</Typography>
                                    <Typography style={theme}>Frame size: {this.state.response_data["pixel_size"] * 1024} nm</Typography>
                                    <Typography style={theme}>Image pixel size: {this.state.response_data["pixel_size"]} nm</Typography>
                                    <Typography style={theme}>Distortion direction: {this.state.response_data["angle"] === 90 ? "Y Axis" : "X Axis"}</Typography>
                                </Grid>
                                <Grid item lg={3} xs={6}><Typography style={theme}><b>Note!</b></Typography>
                                 <Typography style={theme}>The image is a digitally altered simulation with the ripple in only X or Y axis, 
                                    depending on which has the highest value. Z distortion is always represented in X axis. 
                                    Approximately 1nm of ripple in the image is acceptable for most applications. 
                                    The standard digital filtering will help to improve the image.</Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} spacing={1} container direction="row" justifyContent="center">
                            <Grid item md={12} lg={10}>
                                <img style={{ width: "100%", height: "auto" }} alt="Test" src={`data:image/jpeg;base64,${this.state.pic}`} title="Test Image RS with 10.000x magnification" />
                            </Grid>
                            <Grid item md={12} lg={2} >
                                <Grid container style={{ padding: 5 }}>
                                    <Typography style={theme} className="MB15"><b>System settings</b></Typography>
                                    <Grid spacing={2} container direction="row" item lg={7} xs={12}>
                                        <Grid item md={6} lg={12} style={{ paddingTop: 0 }}>
                                            <FormControl className="MB23" fullWidth={true}>
                                                <InputLabel style={theme} id="Input value of beam energy"
                                                    className={classes.inputRoot}>Beam
                                                    energy</InputLabel>
                                                <Select labelId="Label beam energy" style={{ fontSize: "12.5px", marginTop: "5px" }}
                                                    id="Select beam energy"
                                                    value={this.state.beamEnergy}
                                                    MenuProps={{ classes: { paper: classes.menuPaper } }}
                                                    onChange={handleChangeBeamEnergy}
                                                >
                                                    {this.state.selectOptionsEnergy}
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        <Grid item md={6} lg={12} style={{ paddingTop: 0 }}>
                                            <FormControl className="MB15" fullWidth={true}>
                                                <InputLabel id="Input value working distance" style={theme}
                                                    className={classes.inputRoot}>Working
                                                    distance</InputLabel>
                                                <Select labelId="Label working distance" style={{ fontSize: "12.5px", marginTop: "5px" }}
                                                    id="Select working distance"
                                                    value={this.state.workingDistance}
                                                    MenuProps={{ classes: { paper: classes.menuPaper } }}
                                                    onChange={handleChangeWorkingDistance}
                                                >
                                                    {this.state.selectOptionsDistance}
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography style={theme}><b>Reduce or Increase the measured AC field </b></Typography>
                                    </Grid>
                                    <Grid container direction="row" justifyContent="center" style={{ marginLeft: '8px', paddingTop: 5 }}>
                                        <Grid item lg={12} sm={1} xs={2}>
                                            <FormControlLabel style={{ paddingLeft: "0px!important" }} className="radioButtons" control={<Radio style={{ padding: 1 }} size="small" color="primary" onChange={handleChangeRadioButtons} checked={Number(this.state.overhead) === 0} value={0} />} label={<Typography style={theme}>-100%</Typography>} />
                                        </Grid>
                                        <Grid item lg={12} sm={1} xs={2}>
                                            <FormControlLabel className="radioButtons" control={<Radio style={{ padding: 1 }} size="small" color="primary" onChange={handleChangeRadioButtons} checked={Number(this.state.overhead) === 0.5} value={0.5} />} label={<Typography style={theme}>-50%</Typography>} />
                                        </Grid>
                                        <Grid item lg={12} sm={1} xs={2}>
                                            <FormControlLabel className="radioButtons" control={<Radio style={{ padding: 1 }} size="small" color="primary" onChange={handleChangeRadioButtons} checked={Number(this.state.overhead) === 0.75} value={0.75} />} label={<Typography style={theme}>-25%</Typography>} />
                                        </Grid>
                                        <Grid item lg={12} sm={1} xs={2}>
                                            <FormControlLabel className="radioButtons" control={<Radio size="small" style={{ padding: 1 }} color="primary" onChange={handleChangeRadioButtons} checked={Number(this.state.overhead) === 0.90} value={0.90} />} label={<Typography style={theme}>-10%</Typography>} />
                                        </Grid>
                                        <Grid item lg={12} sm={1} xs={2}>
                                            <FormControlLabel className="radioButtons" control={<Radio size="small" style={{ padding: 1 }} color="primary" onChange={handleChangeRadioButtons} checked={Number(this.state.overhead) === 0.95} value={0.95} />} label={<Typography style={theme}>-5%</Typography>} />
                                        </Grid>
                                        <Grid item lg={12} sm={1} xs={2}>
                                            <FormControlLabel className="radioButtons" control={<Radio size="small" style={{ padding: 1 }} color="primary" onChange={handleChangeRadioButtons} checked={Number(this.state.overhead) === 1} value={1} />} label={<Typography style={theme}>None</Typography>} />
                                        </Grid>
                                        <Grid item lg={12} sm={1} xs={2}>
                                            <FormControlLabel className="radioButtons" control={<Radio size="small" style={{ padding: 1 }} color="primary" onChange={handleChangeRadioButtons} checked={Number(this.state.overhead) === 1.05} value={1.05} />} label={<Typography style={theme}>5%</Typography>} />
                                        </Grid>
                                        <Grid item lg={12} sm={1} xs={2}>
                                            <FormControlLabel className="radioButtons" control={<Radio size="small" style={{ padding: 1 }} color="primary" onChange={handleChangeRadioButtons} checked={Number(this.state.overhead) === 1.1} value={1.1} />} label={<Typography style={theme}>10%</Typography>} />
                                        </Grid>
                                        <Grid item lg={12} sm={1} xs={2}>
                                            <FormControlLabel className="radioButtons" control={<Radio size="small" style={{ padding: 1 }} color="primary" onChange={handleChangeRadioButtons} checked={Number(this.state.overhead) === 1.25} value={1.25} />} label={<Typography style={theme}>25%</Typography>} />
                                        </Grid>
                                        <Grid item lg={12} sm={1} xs={2}>
                                            <FormControlLabel className="radioButtons" control={<Radio size="small" style={{ padding: 1 }} color="primary" onChange={handleChangeRadioButtons} checked={Number(this.state.overhead) === 1.5} value={1.5} />} label={<Typography style={theme}>50%</Typography>} />
                                        </Grid>
                                        <Grid item lg={12} sm={1} xs={2}>
                                            <FormControlLabel className="radioButtons" control={<Radio size="small" style={{ padding: 1 }} color="primary" onChange={handleChangeRadioButtons} checked={Number(this.state.overhead) === 2} value={2} />} label={<Typography style={theme}>100%</Typography>} />
                                        </Grid>
                                    </Grid>
                                    <div style={{ width: 125 }}>
                                        <div style={{ height: "auto" }}>
                                            <div style={{ borderWidth: "1px", borderStyle: "solid", padding: "3px" }}>
                                                <Typography style={this.state.overhead === 1 ? { fontSize: "12.5px", fontWeight: "bold" } : { fontSize: "12.5px", fontWeight: "bold", color: "red" }}>{this.state.overhead === 1 ? "EMF strength:" : "Reduced or Increased EMF:"}</Typography>
                                                <Typography style={this.state.overhead === 1 ? { fontSize: "12.5px", fontWeight: "bold" } : { fontSize: "12.5px", fontWeight: "bold", color: "red" }}>
                                                    {(Math.max(parseFloat(this.state.input_data["X"]["distortion_value"]), parseFloat(this.state.input_data["Y"]["distortion_value"]), parseFloat(this.state.input_data["Z"]["distortion_value"])) * this.state.overhead).toFixed(4) + " mG"}
                                                </Typography>
                                            </div>
                                        </div>
                                    </div>
                                </Grid>
                                <Box sx={{ display: { xs:'none', sm:'none', md: 'none', lg: 'block' } }} style={{ height: "80px" }} />
                                <Grid container direction="row" spacing={3} style={{ paddingLeft: "3px" }}>
                                    <Grid item lg={12} md={6} >
                                        <Button fullWidth={true} variant="contained" size="small" onClick={this.goBacktoInput} style={{ height: "40px", lineHeight: "normal" }}>
                                            <ArrowBackIcon style={{ marginRight: 5 }} />Back to settings
                                        </Button>
                                    </Grid>
                                    <Grid item lg={12} md={6}>
                                        <Button fullWidth={true} size="small" variant="contained" onClick={this.exportFile} style={{ height: "40px", lineHeight: "normal" }}>
                                            <SaveIcon style={{ marginRight: 10 }} /> Export simulation
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} spacing={1} container>
                            <Typography style={{ padding: "23px 8px 0px 0" }}><b>Magnification (Polaroid
                                4"X5") </b></Typography>
                        </Grid>
                        <Grid item xs={12} spacing={1} container>

                            <Grid container direction="row" justifyContent="flex-start" alignItems="center">
                                <Grid item xs={10} md={10} xl={10}>

                                    <Card className={classes.r343oot} fullwidth="true"
                                        style={{ width: "100%", marginRight: "50px" }}>
                                        {/*<CardMedia
                                                className={classes.media}
                                                image={this.state.pic}
                                                title="Test Image RS with 10.000x magnification"
                                            />*/}
                                        <CardActions fullwidth="true" className={classes.inpage}
                                            style={{ width: "100%", paddingTop: 0 }}>
                                            <Magnification style={{ marginTop: 5 }}
                                                className={classes.slider}
                                                id={"magnification-slider"}
                                                value={this.state.value}
                                                step={null}
                                                min={this.state.min_value}
                                                max={this.state.max_value}
                                                aria-valuemin={10000}
                                                aria-valuemax={500000}
                                                track={"normal"}
                                                marks={this.state.marks}
                                                aria-labelledby="vertical-slider"
                                                getAriaValueText={valuetext}
                                                onChange={this.updateValue}
                                            />
                                        </CardActions>
                                    </Card>

                                </Grid>
                                <Grid style={{ height: "100%" }} item xs={2} md={2} xl={2}>
                                    <Button variant="contained" color="primary" size="small"
                                        style={{ height: "100%", width:"95%", lineHeight: "normal", marginLeft: 8 }}
                                        onClick={this.refreshImage}
                                        disabled={this.state.workingDistance === ""}>
                                        <SyncIcon className={ this.state.refreshingImage===true ? "icon-spin" : null} style={{ marginRight: 10 }} />Apply change</Button>
                                </Grid>
                            </Grid>
                        </Grid>
                        <div style={{ height: "100px" }} />
                    </Grid>
                    {/*<div className="footer">
                            <div className="page">
                                ZEISS International, &copy; 2021
                            </div>
                        </div>*/}
                    <Box sx={{ display: { xs: 'none', md: 'block' } }}>
                        <Button style={{ position: "fixed", bottom: "0", right: "0", marginRight: "2%", marginBottom: "2%", width: "150px" }}
                            href="mailto:techsupport.sem.microscopy@zeiss.com?subject=Ripple%20Simulator%20Feedback&body=Hello!%0D%0AI%20have%20some%20feedback%20about%20the%20app.%0D%0A"
                            variant="contained" fullWidth={true} color="primary" size={"large"}>
                            <Feedback fontSize="large" style={{ marginRight: "5px" }} />
                            Feedback
                        </Button>
                    </Box>
                    <Box sx={{ display: { xs: 'block', md: 'none' } }}>
                        <Button style={{ position: "fixed", bottom: "0", right: "0", marginRight: "2%", marginBottom: "2%", width: "50px" }}
                            href="mailto:techsupport.sem.microscopy@zeiss.com?subject=Ripple%20Simulator%20Feedback&body=Hello!%0D%0AI%20have%20some%20feedback%20about%20the%20app.%0D%0A"
                            variant="contained" fullWidth={true} color="primary" size={"large"}>
                            <Feedback fontSize="large" style={{ marginRight: "5px" }} />
                        </Button>
                    </Box>
                </Container>
            }
        </>;
    }
}

export default withRouter(withStyles(styles)(VerticalSlider));