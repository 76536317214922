import axios from "axios"
import {baseAddress} from "../tools/baseAddress"
let address = baseAddress+"users/login";

export const login = user => {
  return axios
    .post(address, {
      email: user.email,
      password: user.password
    })
    .then(response => {
      if(response.data.token !== undefined)
        localStorage.setItem('token',response.data.token);
      return response.data
    })
    .catch(err => {
      console.log(err)
    })
}