import React from "react";
import {Route} from "react-router-dom";


export const MyRoute = ({component: Component, ...rest}) => (
  <Route {...rest}
         render={props => Component.render === undefined?Component.map((comp, key) => (React.createElement(comp, {
           ...props,
           key: key + "-my-router" + rest.path
         }))):<Component {...props}/>
         }/>
);