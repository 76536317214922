import axios from "axios/index";
import { baseAddress } from "../../../tools/baseAddress";
import 'grapesjs/dist/css/grapes.min.css';
let addressAddSystem = baseAddress + "add_system";
export const uploadFiles = (csvMagnetic, csvAcoustic, csvSeismic, zip, moreInfo, moreInfoProject) => {
  var formData = new FormData();
  formData.append("magneticCsv", csvMagnetic);
  formData.append("acousticCsv", csvAcoustic);
  formData.append("seismicCsv", csvSeismic);
  formData.append("archive", zip);
  formData.append("token", localStorage.getItem("token"))
  if (moreInfo !== null) {
    formData.append("moreInfo", moreInfo);
    formData.append("moreInfoProject", JSON.stringify(moreInfoProject));
  }
  return axios
    .post(addressAddSystem, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
    .then(response => {
      return response
    })
    .catch(err => {
      if (err.response === undefined || err.status === 500 || "500" in err.response)
        return { "data": "Internal server error! Please try again." };
      if (err.response.status === 413)
        return { "data": "The file size can't exceed 150 MB" };
      return err.response
    })
}