import { Typography, Grid } from "@material-ui/core";
export const getPeakDistortionsInterface = (distortions, distortionType) => {
    let elements = []
    let unitOfMeasurement = distortionType=="acoustic" ? " dB" : " µm/s"; 
    distortions.forEach(element => {
        elements.push(
            <Grid item key={element["interval"]} style={{marginTop:"10px"}}>
                <Typography style={{fontWeight:"bold", fontSize:"12.5px"}}>{element["interval"]}</Typography>
                <Typography style={{fontSize:"12.5px"}}>{Math.round(parseFloat(element["distortion_value"]) * 100) / 100}{unitOfMeasurement}, @ {Math.round(parseFloat(element["frequency"]) * 100) / 100}Hz</Typography>
            </Grid>)
    });
    return elements;
}