import React from "react";
import {Redirect, Route} from "react-router-dom";


export const PrivateRoute = ({component: Component, ...rest}) => (
  localStorage.getItem("token") ? <Route {...rest} render={props => (
    (Component.render === undefined?
        Component.map((comp, key) => (
          React.createElement(comp, {
            ...props,
            key: key + "my-route"+rest.path
          })
        )):<Component {...props}/>
    )
  )} />: <Redirect to={{pathname: "/login"}}/>
);