import axios from "axios/index";
import { baseAddress } from "../../tools/baseAddress"
let address = baseAddress + "inputs_data";
let addressGraphicGenerator = baseAddress + "graphic_generator";
export const getInputData = () => {
  return axios
    .post(address, {
      token: localStorage.getItem("token")
    })
    .then(response => {
      return response
    })
    .catch(err => {
      console.log(err)
    })
}

export const dataDashboard = async () => await axios.post('/api/dashboard', {
  token: localStorage.getItem("token")
});


export const getGraph = (txt, systemName, distortionType, axis_type) => {
  var formData = new FormData();
  formData.append("txtfile", txt);
  formData.append("system_name", systemName)
  formData.append("distortion_type", distortionType)
  formData.append("token", localStorage.getItem("token"))
  if (distortionType !== undefined)
    formData.append("axis_type", axis_type)
  return axios
    .post(addressGraphicGenerator, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Access-Control-Allow-Origin': '*'
      }
    })
    .then(response => {
      return response.data;
    })
    .catch(err => {
      return err.response
    })
}
