import axios from "axios/index";
import {baseAddress} from "../../../tools/baseAddress";
let addressGetAdminList = baseAddress + "get_admin_list";
let addressAddAdmin = baseAddress + "add_admin";
let addressDeleteAdmin = baseAddress + "delete_admin";
export const getAdminList = () => {
  return axios
    .post(addressGetAdminList, {
      token: localStorage.getItem("token")
    })
    .then(response => {
      return response
    })
    .catch(err => {
      console.log(err)
    })
}

export const addAdmin = (email) => {
  return axios
    .post(addressAddAdmin, {
      token: localStorage.getItem("token"),
      email: email
    })
    .then(response => {
      return response
    })
    .catch(err => {
      return err.response
    })
}


export const deleteAdmin = (email) => {
  return axios
    .post(addressDeleteAdmin, {
      token: localStorage.getItem("token"),
      email: email
    })
    .then(response => {
      return response
    })
    .catch(err => {
      return err.response
    })
}
