import React from "react";
import { Container, Grid, TextField } from '@material-ui/core';
import Button from "@material-ui/core/Button";
import Typography from '@material-ui/core/Typography';
import DeleteIcon from '@material-ui/icons/Delete';
import { getAdminList, addAdmin, deleteAdmin } from "../controller/AdminListController";
class AdminPanelAdminList extends React.Component {
    constructor(props) {
        super(props);
        this.refreshAdminList();
        this.state = {
            inputEmail: "",
            adminList: <div />,
            errorMessage: ""
        }
    }
    reloadPage() {
        window.location.reload();
    }
    refreshAdminList = async () => {
        let response = await getAdminList();

        if ('redirect' in response) {
            this.props.history.push('/login')
        }
        if (response.status == 200) {
            let admins = [];
            let index;
            for (index = 0; index < response.data["admin_list"].length; index++) {
                let thisAdmin = response.data["admin_list"][index];
                admins.push(
                    <div style={{ display: "flex" }} key={"admin"+thisAdmin}>
                        <Typography style={{ marginTop: "8px" }}>
                            {thisAdmin}
                        </Typography>
                        <Button onClick={this.handleDeleteAdmin.bind(this, thisAdmin)}>
                            <DeleteIcon />
                        </Button>
                    </div>
                )
            }
            this.setState({
                adminList: admins,
                errorMessage: ""
            })
        }
    }

    handleAddAdmin = async () => {
        let response = await addAdmin(this.state.inputEmail)

        if (response == undefined)
            return;

        if ('redirect' in response) {
            this.props.history.push('/login')
        }
        if (response.status == 200) {
            this.refreshAdminList();
            this.setState({ inputEmail: "" })
        }
        else
            this.setState({ errorMessage: response['data'] })
    }

    handleDeleteAdmin = async (email) => {
        let response = await deleteAdmin(email);
        if (response == undefined)
            return;

        if ('redirect' in response) {
            this.props.history.push('/login')
        }
        if (response.status == 200)
            this.refreshAdminList();
        else this.setState({
            errorMessage: response['data']
        })
    }
    render() {
        const onChangeInputEmail = (event) => {
            this.setState({ inputEmail: event.target.value })
        }
        return <><Container>
            <Grid container justifyContent="center">
                <Grid item xs={12} sm={8} container direction="column">
                    <Grid item>
                        <h2>
                            ADMIN LIST
                        </h2>
                    </Grid>
                    <Grid item>
                        <div>
                            {this.state.adminList}
                        </div>
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={4} container direction="column">
                    <Grid item>
                        <h2 style={{ width: "fit-content" }}>
                            NEW ADMIN
                        </h2>
                    </Grid>
                    <Grid item>
                        <TextField placeholder="Input an email" style={{ height: "5px" }} value={this.state.inputEmail} onChange={onChangeInputEmail}></TextField>
                        <Button variant="contained" size="small" onClick={this.handleAddAdmin.bind(this)}>
                            Add
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button style={{ marginTop: "50px" }} onClick={this.reloadPage.bind(this)} variant="contained">
                            Back to admin panel
                        </Button>
                    </Grid>
                    <Grid item>
                        <Typography style={{ color: "red", marginTop: "20px" }}>
                            {this.state.errorMessage}
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
        </Container>
        </>;
    }
}

export default AdminPanelAdminList;
