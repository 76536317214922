import axios from "axios/index";
import {baseAddress} from "../../../tools/baseAddress"
let addressGenerateImage = baseAddress + "api/generate_image";
let addressExportPDF = baseAddress + "exportPDF";
export const generate_img = (dataInput) => {
    return axios
        .post(addressGenerateImage, {
            dataInput: dataInput,
            token: localStorage.getItem("token")
        })
        .then(response => {
            return response
        })
        .catch(err => {
            console.log(err)
        })
};
export const exportPDF = (patameters) => {

    return axios
        .post(addressExportPDF, {
            param: patameters,
            token: localStorage.getItem("token")
        })
        .then(response => {
            return response
        })
        .catch(err => {
            console.log(err)
        })
};