import React from "react";
import {withStyles} from '@material-ui/core/styles';
import Grid from "@material-ui/core/Grid"
import TextField from "@material-ui/core/TextField"
import Button from "@material-ui/core/Button"
import Avatar from "@material-ui/core/Avatar";
import applicationLogo from "../../design/zeiss-logo-tagline_rgb.png";
import { login } from "../../functions/UserFunctions"
import { CardHeader } from "@material-ui/core";
import axios from "axios";


// const customTheme = createMuiTheme({
//     overrides: {
//         MuiInput: {
//             input: {
//                 "&::placeholder": {
//                     color: "red"
//                 },
//                 "&::placeholder ":{color:"red"},
//                 color: "white", // if you also want to change the color of the input, this is the prop you'd use
//             }
//         }
//     }});

const styles = theme => ({

    inputRoot: {
        fontSize: 20,
        color:"rgba(0,0,0,0.9)"
    },
    input: {
        '&::placeholder': {
            color:"black",
            boxShadow:"none"
        },


    },
    ssoButton: {
         marginRight: '10px'
     },

    imgLogoSignIn: {
        width: "100%"
    },
    large: {

        backgroundColor: 'white',
        width: theme.spacing(10),
        height: theme.spacing(10),
    }

});


class SignInContainer extends React.Component {
    constructor(props) {
        super(props);
        this.handleNameChange = this.handleNameChange.bind(this);
        this.handlePasswordChange = this.handlePasswordChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.finalizeSSO = this.finalizeSSO.bind(this);
        this.initiateSSO = this.initiateSSO.bind(this);
        this.queryParams = new URLSearchParams(window.location.search);

        this.state = {
            name: "",
            password: "",
            error: null,
        };
    }

    componentDidMount() {
        localStorage.clear();
        if (this.queryParams.get('error')) {
            this.setState({ error: this.queryParams.get('error') })
        } else if (this.queryParams.get('session_index')) {
            this.finalizeSSO(this.queryParams.get('session_index'));
        }
    }

    handleNameChange(event) {
        this.setState({name: event.target.value});
    }

    handlePasswordChange(event) {
        this.setState({password: event.target.value});
    }
    initiateSSO() {
        axios.get('https://ripplesimulatorapi.azurewebsites.net/saml')
            .then((res) => {
                const { data: { sso_url } } = res;
                window.location.href = sso_url
            })
            .catch(err => {
                console.log(err);
            })
    }

    finalizeSSO(sessionIndex) {
        axios.get('https://ripplesimulatorapi.azurewebsites.net/saml/finalize', { params: { session_index: sessionIndex } })
            .then(response => {
                const { data: { token, user_data } } = response;
                if (token !== undefined)  localStorage.setItem('token', token);
                this.props.history.push('/inputs')
            })
            .catch(err => {
                console.log(err)
            })
    }

    onSubmit(event) {
        event.preventDefault();
        event.stopPropagation();

        const user = {
            email: this.state.name,
            password: this.state.password
        };

        this.setState({
            error: null
        })

        login(user).then(res => {
            if (res.error) {
                this.setState({
                    error: "Username or password is invalid!"
                })
            }
            if (res.token !== undefined) {
                this.props.history.push('/inputs')
            }
        })
    }

    render() {
        const {classes} = this.props;

        return (<div className="wrapper">
                <div className="page_header">
                    <div className="page page400">
                    <Grid container direction="row" justifyContent="center" alignItems="center">
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} >
                            <CardHeader
                                avatar={
                                    <Avatar variant={"square"} aria-label="ripple-simulator" src={applicationLogo}
                                            className={classes.large}/>
                                }
                                titleTypographyProps={{variant: "h4", color: 'textPrimary'}}
                                title={"Ripple Simulator"}
                            />
                        </Grid>
                    </Grid>
                    </div>
                </div>
                <div className="page page400 pageBody">
                    <div>
                        {this.state.error && <div role="alert">
                            <div className="MuiAlert-message">{this.state.error}</div>
                        </div>}
                        <form className={classes.container} onSubmit={this.onSubmit}>
{/*                       <div>
                            <TextField
                                id="standard-full-width-username"
                                label="Username"
                                placeholder="Enter Username here"
                                autoComplete="off"
                                // helperText="Full width!"
                                fullWidth
                                value={this.state.name}
                                onChange={(event) => this.handleNameChange(event)}
                                margin="normal"
                                type={"email"}
                                InputLabelProps={{
                                    classes: {root: classes.inputRoot},
                                    shrink: true,
                                }}
                               InputProps={{
                                   classes: { input: classes.input}
                               }}

                            />
                            <TextField
                                className="MB30"
                                id="standard-full-width-password"
                                label="Password"
                                // placeholder=""
                                // helperText="Full width!"
                                fullWidth
                                type="password"
                                autoComplete="current-password"
                                value={this.state.password}
                                onChange={(event) => this.handlePasswordChange(event)}
                                margin="normal"
                                InputLabelProps={{
                                    classes: {root: classes.inputRoot},
                                    shrink: true,
                                }}
                            />
                            </div>
                            */}
                            <Button
                                variant="contained"
                                color="primary"
                                size="large"
                                fullWidth
                                className={classes.ssoButton}
                                onClick={this.initiateSSO}
                            >
                                Sign in with your Zeiss account
                           </Button>
                            {/*<Button
                                type="submit"
//                                fullWidth
                                variant="contained"
                                color="primary"
                                size="large"
                                className={classes.submit}
                            >
                                Sign In
                        </Button>*/}
                        </form>
                    </div>
                </div>
                {/*<div className="footer">
                    <div className="page page400">
                        ZEISS International, &copy; 2021
                    </div>
                </div>*/}
            </div>
        )
    }
}

export default (withStyles(styles)(SignInContainer));