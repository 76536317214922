import React from "react";
import { withStyles } from '@material-ui/core/styles';
import { CardHeader, Grid, TextField, Tabs, Tab, CircularProgress, Container, Box, Switch} from '@material-ui/core';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { getGraph, getInputData } from "../controller/InputAction";
import Avatar from "@material-ui/core/Avatar";
import applicationLogo from "../../design/zeiss-logo-tagline_rgb.png";
import { Feedback } from "@material-ui/icons";
import 'grapesjs/dist/css/grapes.min.css';
import HTML2React from 'html2react'
const styles = theme => ({
    structure: {
        flexGrow: 1,
    },
    inputFieldLabelDistortions: {
        fontSize: 15,
        marginLeft: 0,
        paddingTop:0,
        color: "rgba(0,0,0,0.9)",
    },

    inputRoot: {
        fontSize: 17,
        color: "rgba(0,0,0,0.9)",
        backgroundColor: "white",
    },
    menuPaper: {
        maxHeight: 300,
    },
    formControl: {
        margin: 2,
        minWidth: 120,
    },
    select: {
        textAlign: "center",
        fontSize: 20,
    },
    Input: {
        borderStyle: "solid",
        // borderWidth: "1px",
    },
    large: {

        backgroundColor: 'white',
        width: theme.spacing(10),
        height: theme.spacing(10),
    }

});
const seismicFieldsDefault = {
    "z": { "available": false },
    "xy": { "available": false },
    "xyz": { "available": false }
}
class DashboardContainer extends React.Component {
    constructor(props) {
        super(props);
        this.handleOpenModal = this.handleOpenModal.bind(this);
        this.startSimulation = this.startSimulation.bind(this);
        this.goToAdminPanel = this.goToAdminPanel.bind(this);
        this.processAcousticTxtInputChange = this.processAcousticTxtInputChange.bind(this);
        this.state = {
            jsonResponse: {},
            selectOptionsSystem: [],
            selectOptionsEnergy: [],
            selectOptionsDistance: [],
            systemName: "",
            beamEnergy: "",
            distortionValueX: "",
            distortionValueY: "",
            distortionValueZ: "",
            frequencyX: '',
            frequencyY: '',
            frequencyZ: '',
            selectedTxtAcoustic: "",
            selectedTxtSeismic: "",
            selectedTxtMagnetic: "",
            workingDistance: "",
            magneticField: "",
            selectedTab: 0,
            isTabDisabled: [true, true, true, true],
            imageBytesMagnetic: "",
            imageBytesAcoustic: "",
            inputFieldsAcoustic: [],
            inputFieldsSeismic: [],
            imageBytesSeismic: "",
            axis_type: "",
            seismicAxisAvailable: seismicFieldsDefault,
            fieldsCompletedMagnetic: false,
            fieldsCompletedAcoustic: false,
            fieldsCompletedSeismic: false,
            acousticErrorMessage: "",
            seismicErrorMessage: "",
            magneticErrorMessage: "",
            acousticFileInputValue: "",
            seismicFileInputValue: "",
            magneticFileInputValue: "",
            customer_name: "",
            loadingScreen: false,
            distortionMethod: "Measured",
            inputTXTAcoustic:{},
            inputTXTSeismic:{}

        };
    }
    admin = false;
    componentDidMount() {
        getInputData().then(({ data: response }) => {
            if ('redirect' in response) {
                this.props.history.push('/login')
            }
            this.admin = response.is_admin
            delete response.is_admin
            let copyArray = [];
            for (let key in response) {
                if (response.hasOwnProperty(key)) {
                    copyArray.push(<MenuItem key={key} id={key} value={key}>{key}</MenuItem>)
                }
            }
            this.setState({
                selectOptionsSystem: copyArray,
                jsonResponse: response,
            });
            const localStorageDataInput = localStorage.getItem("dataInput");
            if (this.props.location.state !== undefined) {
                let dataInput = JSON.parse(localStorageDataInput);
                if (response[dataInput["system"]]["magnetic"]["enable"] === true) {
                    let copyArray = [];
                    for (let key in response[dataInput["system"]]["magnetic"]["info"]) {
                        if (response[dataInput["system"]]["magnetic"]["info"].hasOwnProperty(key)) {
                            copyArray.push(<MenuItem id={key} key={key} value={key}>{key}</MenuItem>)
                        }
                    }
                    this.setState({
                        selectOptionsEnergy: copyArray,
                        frequencyX: 50,
                        frequencyY: 50,
                        frequencyZ: 50,
                    })
                }
                if (response[dataInput["system"]]["acoustic"]["enable"] === true)
                    this.initializeAcousticFields(dataInput["system"])
                if (response[dataInput["system"]]["seismic"]["enable"] === true)
                    this.initializeSeismicFields(dataInput["system"])
                this.setState({ customer_name: dataInput["customer_name"] === undefined ? "" : dataInput["customer_name"] })
                switch (dataInput["selectedTab"]) {
                    case 0:
                        {
                            this.setState({
                                systemName: dataInput["system"],
                                beamEnergy: dataInput["energy"]

                            });
                            let copyArrayEnergy = [];
                            for (let key in response[dataInput["system"]]["magnetic"]["info"][dataInput["energy"]]) {
                                if (response[this.state.systemName]["magnetic"]["info"][dataInput["energy"]].hasOwnProperty(key)) {
                                    copyArrayEnergy.push(<MenuItem id={key} key={key}
                                        value={response[this.state.systemName]["magnetic"]["info"][dataInput["energy"]][key]}>{response[dataInput["system"]]["magnetic"]["info"][dataInput["energy"]][key]}</MenuItem>)
                                }
                            }
                            this.setState({
                                selectOptionsDistance: copyArrayEnergy,
                                workingDistance: dataInput["distance"],
                                distortionValueX: dataInput["X"]["distortion_value"] === 0 ? "" : dataInput["X"]["distortion_value"],
                                distortionValueY: dataInput["Y"]["distortion_value"] === 0 ? "" : dataInput["Y"]["distortion_value"],
                                distortionValueZ: dataInput["Z"]["distortion_value"] === 0 ? "" : dataInput["Z"]["distortion_value"],
                                frequencyX: dataInput["X"]["frequency"] === 0 ? "" : dataInput["X"]["frequency"],
                                frequencyY: dataInput["Y"]["frequency"] === 0 ? "" : dataInput["Y"]["frequency"],
                                frequencyZ: dataInput["Z"]["frequency"] === 0 ? "" : dataInput["Z"]["frequency"],
                                isTabDisabled: dataInput["isTabDisabled"],
                                imageBytesMagnetic: dataInput["graphics"] === undefined ? "" : dataInput["graphics"],
                                fieldsCompletedMagnetic: true
                            });
                            break;
                        }
                    case 1:
                        {
                            this.setState({
                                systemName: dataInput["system"],
                                inputFieldsAcoustic: dataInput["dataList"],
                                imageBytesAcoustic: dataInput["graphics"] === undefined ? "" : dataInput["graphics"],
                                selectedTab: dataInput["selectedTab"],
                                isTabDisabled: dataInput["isTabDisabled"],
                                fieldsCompletedAcoustic: true,
                                distortionMethod: dataInput["distortionMethod"],
                                inputTXTAcoustic:dataInput["inputTXTFile"]
                            });

                            break;
                        }
                    case 2:
                        {
                            this.setState({
                                systemName: dataInput["system"],
                                inputFieldsSeismic: dataInput["dataList"],
                                imageBytesSeismic: dataInput["graphics"] === undefined ? "" : dataInput["graphics"],
                                selectedTab: dataInput["selectedTab"],
                                isTabDisabled: dataInput["isTabDisabled"],
                                axis_type: dataInput["axis_type"],
                                seismicAxisAvailable: dataInput["seismicAxisAvailable"],
                                fieldsCompletedSeismic: true,
                                seismicFileInputValue: "None", //tricking it into showing the reset fields button without having a file uploaded
                                distortionMethod: dataInput["distortionMethod"],
                                inputTXTSeismic:dataInput["inputTXTFile"]

                            });
                            break;
                        }
                    default:
                        break;
                }
            }
        });

    }
    processAcousticTxtInputChange = async (event) => {
        if (event.target.files.length === 0)
            return;
        this.setState({ selectedTxtAcoustic: event.target.files[0], acousticFileInputValue: event.target.value, loadingScreen: true })
        let response = await getGraph(event.target.files[0], this.state.systemName, "acoustic");
        this.setState({ loadingScreen: false })
        const isOK = response.Status === "Success"
        if (!isOK) {
            if ('redirect' in response.data)
                this.props.history.push('/login')
            let problemsFound = "";
            const isString = typeof response.data === "string" || response.data instanceof String;
            if (!isString) {
                for (const [key, value] of Object.entries(response.data)) {
                    problemsFound += (key + ":\n")
                    let index;
                    for (index = 0; index < Object.values(value).length; index++) {
                        problemsFound += ("- " + Object.values(value)[index] + '\n');
                    }
                }
            }
            else problemsFound = response.data;
            this.setState({
                acousticErrorMessage: problemsFound,
                imageBytesAcoustic: "",
                fieldsCompletedAcoustic: false,
            })
            this.initializeAcousticFields(this.state.systemName);
            return
        }
        let inputFieldsAcoustic = []
        for (const val of Object.entries(response["Intervals"]["a"]["maxim_intervals"])) {
            inputFieldsAcoustic.push
                ({
                    "frequency": String(val[1]["frequency"]),
                    "distortion_value": String(val[1]["maximum"]),
                    "interval": val[1]["start"] + "Hz - " + val[1]["end"] + "Hz"
                })
        }
        this.setState({ inputFieldsAcoustic, imageBytesAcoustic: response["Intervals"]["a"]["ImageBytes"], fieldsCompletedAcoustic: true, acousticErrorMessage: "", inputTXTAcoustic: response["dataFileTxt"] });
    }
    processMagneticTxtInputChange = async (event) => {
        if (event.target.files.length === 0)
            return;
        this.setState({ selectedTxtMagnetic: event.target.files[0], magneticFileInputValue: event.target.value, loadingScreen: true })
        let response = await getGraph(event.target.files[0], this.state.systemName, "magnetic");
        this.setState({ loadingScreen: false })
        if ('redirect' in response) {
            this.props.history.push('/login')
        }
        const isOK = response.Status === "Success"
        if (!isOK) {
            let problemsFound = "";
            const isString = typeof response.data === "string" || response.data instanceof String;
            if (!isString) {
                for (const [key, value] of Object.entries(response.data)) {
                    problemsFound += (key + ":\n")
                    let index;
                    for (index = 0; index < Object.values(value).length; index++) {
                        problemsFound += ("- " + Object.values(value)[index] + '\n');
                    }
                }
            }
            else problemsFound = response.data;
            this.setState({
                magneticErrorMessage: problemsFound
            })
            this.initializeMagneticFields();
            return
        }
        this.setState({
            imageBytesMagnetic: response["Intervals"]["ImageBytes"],
            frequencyX: response["Intervals"]["x"]["frequency"],
            frequencyY: response["Intervals"]["y"]["frequency"],
            frequencyZ: response["Intervals"]["z"]["frequency"],
            distortionValueX: response["Intervals"]["x"]["maximum"],
            distortionValueY: response["Intervals"]["y"]["maximum"],
            distortionValueZ: response["Intervals"]["z"]["maximum"],
            magneticErrorMessage: ""
        })
        this.handleStartSimulationButtonEnableMagnetic();
    }
    updateInterfaceAcoustic(classes) {
        return this.state.inputFieldsAcoustic.map((el, i) =>
            <Grid item key={i} style={{ marginTop: "10px" }} container direction="row" alignItems="flex-start">
                <Grid container>
                    <Grid item xs={4} justifyContent="center" container>
                        <Grid item>
                            <Typography style={{fontSize:"11px", marginTop: "17px", marginRight:"5px"}}>{el["interval"]}</Typography>
                        </Grid>
                    </Grid>
                    <Grid spacing={1} container direction="row" item xs={8}>
                        <Grid item xs={6}>
                            <FormControl fullWidth={true}>
                                <TextField label="Frequency (Hz)"
                                    variant="outlined" type="number"
                                    value={el["frequency"]}
                                    onChange={this.handleAcousticFrequencyChange.bind(this, i)}
                                    InputLabelProps={
                                        {
                                        classes: {root: classes.inputFieldLabelDistortions }, shrink:true
                                    }}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl fullWidth={true}>
                                <TextField label="Acoustic level (dB)"
                                    variant="outlined" type="number"
                                    value={el["distortion_value"]}
                                    onChange={this.handleAcousticACLevelChange.bind(this, i)}
                                    InputLabelProps={{
                                        classes: { root: classes.inputFieldLabelDistortions }, shrink:true
                                    }}
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        )
    }

    handleAcousticFrequencyChange(i, event) {
        let inputFieldsAcoustic = [...this.state.inputFieldsAcoustic];
        inputFieldsAcoustic[i]["frequency"] = event.target.value;
        this.setState({ inputFieldsAcoustic });
        this.handleStartSimulationButtonEnableAcoustic();
    }
    handleAcousticACLevelChange(i, event) {
        let inputFieldsAcoustic = [...this.state.inputFieldsAcoustic];
        inputFieldsAcoustic[i]["distortion_value"] = event.target.value;
        this.setState({ inputFieldsAcoustic });
        this.handleStartSimulationButtonEnableAcoustic();
    }

    handleStartSimulationButtonEnableMagnetic() {
        this.setState({ magneticErrorMessage: "" })
        
        if (((this.state.frequencyX !== "" && this.state.distortionValueX !== "") ||
            (this.state.frequencyY !== "" && this.state.distortionValueY !== "") ||
            (this.state.frequencyZ !== "" && this.state.distortionValueZ !== "")) &&
            (
            !(this.state.frequencyX === "" && this.state.distortionValueX !== "") &&
            !(this.state.frequencyY === "" && this.state.distortionValueY !== "") &&
            !(this.state.frequencyZ === "" && this.state.distortionValueZ !== "")) &&
            this.state.workingDistance !== "" && this.state.beamEnergy !== "") {
            this.setState({ fieldsCompletedMagnetic: true })
            return;
        }
        this.setState({ fieldsCompletedMagnetic: false })
    }

    handleStartSimulationButtonEnableAcoustic() {
        let atLeastOneCompleted = false;
        let problemFound = false;
        let acousticErrorMessage = "";
        this.state.inputFieldsAcoustic.forEach((element) => {
            //we need at least a pair completed 
            if (element["frequency"] !== "" && element["distortion_value"] !== "")
                atLeastOneCompleted = true;
            //if we have a pair partially completed, we can't continue
            if ((element["frequency"] === "" || element["distortion_value"] === "") && !(element["frequency"] === "" && element["distortion_value"] === "")) {
                problemFound = true;
                return;
            }
            //checking if the interval is respected 
            if (element["frequency"] !== "") {
                const range = element["interval"].split(" - ");
                const floor = parseFloat(range[0].replace("Hz", "")),
                    ceiling = parseFloat(range[1].replace("Hz", "")),
                    frequency = parseFloat(element["frequency"]);
                if (frequency < floor || frequency > ceiling) {
                    acousticErrorMessage = "The frequency levels must be within their respective intervals";
                    problemFound = true;
                    return;
                }
            }
        })
        this.setState({ fieldsCompletedAcoustic: atLeastOneCompleted && !problemFound, acousticErrorMessage });
    }

    updateInterfaceSeismic(classes) {
        return this.state.inputFieldsSeismic.map((el, i) =>
            <Grid item key={i} style={{ marginTop: "10px" }} container direction="row" alignItems="flex-start">
                <Grid container>
                    <Grid item xs={4} justifyContent="center" container>
                            <Grid item>
                            <Typography style={{fontSize:"11px", marginTop: "15px", marginRight:"5px"}}>{el["interval"]}</Typography>
                        </Grid>
                    </Grid>
                    <Grid spacing={1} container direction="row" item xs={8}>
                        <Grid item xs={6}>
                            <FormControl fullWidth={true}>
                                <TextField label="Frequency (Hz)"
                                    variant="outlined" type="number"
                                    value={el["frequency"]}
                                    onChange={this.handleSeismicFrequencyChange.bind(this, i)}
                                    InputLabelProps={{
                                        classes: { root: classes.inputFieldLabelDistortions }, shrink:true
                                    }}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl fullWidth={true}>
                                <TextField label="Seismic level (μm/s)"
                                    variant="outlined" type="number"
                                    value={el["distortion_value"]}
                                    onChange={this.handleSeismicACLevelChange.bind(this, i)}
                                    InputLabelProps={{
                                        classes: { root: classes.inputFieldLabelDistortions }, shrink:true
                                    }}
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

        )
    }
    handleStartSimulationButtonEnableSeismic() {
        let atLeastOneCompleted = false;
        let problemFound = false;
        let seismicErrorMessage = "";
        this.state.inputFieldsSeismic.forEach((element) => {
            //we need at least a pair completed 
            if (element["frequency"] !== "" && element["distortion_value"] !== "")
                atLeastOneCompleted = true;
            //if we have a pair partially completed, we can't continue
            if ((element["frequency"] === "" || element["distortion_value"] === "") && !(element["frequency"] === "" && element["distortion_value"] === "")) {
                problemFound = true;
                return;
            }
            //checking if the interval is respected 
            if (element["frequency"] !== "") {
                const range = element["interval"].split(" - ");
                const floor = parseFloat(range[0].replace("Hz", "")),
                    ceiling = parseFloat(range[1].replace("Hz", "")),
                    frequency = parseFloat(element["frequency"]);
                if (frequency < floor || frequency > ceiling) {
                    seismicErrorMessage = "The frequency levels must be within their respective intervals";
                    problemFound = true;
                    return;
                }
            }
        })
        this.setState({ fieldsCompletedSeismic: atLeastOneCompleted && !problemFound, seismicErrorMessage });
    }
    handleSeismicFrequencyChange(i, event) {
        let inputFieldsSeismic = [...this.state.inputFieldsSeismic];
        inputFieldsSeismic[i]["frequency"] = event.target.value;
        this.setState({ inputFieldsSeismic });
        this.handleStartSimulationButtonEnableSeismic();
    }
    handleSeismicACLevelChange(i, event) {
        let inputFieldsSeismic = [...this.state.inputFieldsSeismic];
        inputFieldsSeismic[i]["distortion_value"] = event.target.value;
        this.setState({ inputFieldsSeismic });
        this.handleStartSimulationButtonEnableSeismic();
    }
    processSeismicTxtInputChange = async (event) => {
        if (event.target.files.length === 0)
            return;
        this.setState({ selectedTxtSeismic: event.target.files[0], seismicFileInputValue: event.target.value, loadingScreen: true })
        let response = await getGraph(event.target.files[0], this.state.systemName, "seismic", this.state.axis_type);
        this.setState({ loadingScreen: false })

        const isOK = response.Status === "Success"
        if (!isOK) {
            if ('redirect' in response.data) {
                this.props.history.push('/login')
            }
            let problemsFound = "";
            const isString = typeof response.data === "string" || response.data instanceof String;
            if (!isString) {
                for (const [key, value] of Object.entries(response.data)) {
                    problemsFound += (key + ":\n")
                    let index;
                    for (index = 0; index < Object.values(value).length; index++) {
                        problemsFound += ("- " + Object.values(value)[index] + '\n');
                    }
                }
            }
            else problemsFound = response.data;
            this.setState({
                seismicErrorMessage: problemsFound,
                imageBytesSeismic: "",
                fieldsCompletedSeismic: false,
            })
            this.initializeSeismicFields(this.state.systemName);
            return
        }
        let seismicAxisAvailable = {
            "z": { "available": false },
            "xy": { "available": false },
            "xyz": { "available": false }
        };
        for (const [key, val] of Object.entries(response["Intervals"])) {
            let inputFields = [];
            for (const interVal of Object.values(val["maxim_intervals"])) {
                const intervalLabel = interVal["start"] + "Hz - " + interVal["end"] + "Hz";
                inputFields.push({ "frequency": String(interVal["frequency"]), "distortion_value": String(interVal["maximum"]), "interval": intervalLabel })
            }
            seismicAxisAvailable[key]["available"] = true;
            seismicAxisAvailable[key]["inputFields"] = inputFields;
            seismicAxisAvailable[key]["imageBytes"] = val["ImageBytes"];
        }
        let axis = ""
        //selecting the first available axis
        for (const [key, val] of Object.entries(seismicAxisAvailable)) {
            if (val["available"] === true) {
                axis = key;
                break;
            }
        }
        this.setState({
            seismicAxisAvailable, fieldsCompletedSeismic: true, seismicErrorMessage: "",
            axis_type: axis, inputFieldsSeismic: seismicAxisAvailable[axis]["inputFields"],
            imageBytesSeismic: seismicAxisAvailable[axis]["imageBytes"], inputTXTSeismic: response["dataFileTxt"]
        })
    }


    handleTabChange = (event, value) => {
        this.setState({ selectedTab: value });

        if((value === 1 || value === 2) && this.state.distortionMethod === "Resonant"){
            if(value === 1 &&  Object.keys(this.state.inputTXTAcoustic).length === 0)
                this.setState({ distortionMethod: "Measured" });
            if(value === 2 &&  Object.keys(this.state.inputTXTSeismic).length === 0)
                this.setState({ distortionMethod: "Measured" });
        }
    }
    handleDistortionMethod = (event) => {
        this.setState({ distortionMethod: event.target.value});
    };
    handleOpenModal() {
        this.setState({ openModal: !this.state.openModal });
    }

    goToAdminPanel() {
        this.props.history.push('/admin')
    }
    startSimulation() {
        let dataInput = {}
        switch (this.state.selectedTab) {
            case 0:
                {
                    let x = {
                        "distortion_value": this.state.distortionValueX === "" ? 0 : this.state.distortionValueX,
                        "frequency": this.state.frequencyX === "" ? 0 : this.state.frequencyX,
                    },
                        y = {
                            "distortion_value": this.state.distortionValueY === "" ? 0 : this.state.distortionValueY,
                            "frequency": this.state.frequencyY === "" ? 0 : this.state.frequencyY,
                        },
                        z = {
                            "distortion_value": this.state.distortionValueZ === "" ? 0 : this.state.distortionValueZ,
                            "frequency": this.state.frequencyZ === "" ? 0 : this.state.frequencyZ,
                        };
                    dataInput = {
                        selectOptionsEnergy: this.state.selectOptionsEnergy,
                        selectOptionsDistance: this.state.selectOptionsDistance,
                        system: this.state.systemName,
                        energy: this.state.beamEnergy,
                        distance: this.state.workingDistance,
                        X: x,
                        Y: y,
                        Z: z,
                        frequency: this.state.frequency,
                        distortion_type: "magnetic",
                        selectedTab: this.state.selectedTab,
                        isTabDisabled: this.state.isTabDisabled
                    };
                    if (this.state.imageBytesMagnetic !== "")
                        dataInput["graphics"] = this.state.imageBytesMagnetic;
                    break;
                }
            case 1:
                {
                    dataInput = {
                        system: this.state.systemName,
                        dataList: this.state.inputFieldsAcoustic,
                        distortion_type: "acoustic",
                        selectedTab: this.state.selectedTab,
                        isTabDisabled: this.state.isTabDisabled,
                        distortionMethod:this.state.distortionMethod,
                        inputTXTFile: this.state.inputTXTAcoustic
                    };
                    if (this.state.imageBytesAcoustic !== "")
                        dataInput["graphics"] = this.state.imageBytesAcoustic;
                    break;
                }
            case 2:
                {
                    dataInput = {
                        system: this.state.systemName,
                        dataList: this.state.inputFieldsSeismic,
                        distortion_type: "seismic",
                        selectedTab: this.state.selectedTab,
                        isTabDisabled: this.state.isTabDisabled,
                        axis_type: this.state.axis_type,
                        seismicAxisAvailable: this.state.seismicAxisAvailable,
                        distortionMethod:this.state.distortionMethod,
                        inputTXTFile: this.state.inputTXTSeismic
                    };
                    if (this.state.imageBytesSeismic !== "")
                        dataInput["graphics"] = this.state.imageBytesSeismic;

                    break;
                }
            default:
                break;
        }
        if (this.state.customer_name !== "")
            dataInput["customer_name"] = this.state.customer_name
        const json = JSON.stringify(dataInput);
        localStorage.setItem("dataInput", json);
        switch (this.state.selectedTab) {
            case 0:
                {
                    this.props.history.push({
                        pathname: '/output_magnetic',
                        state: this.state.jsonResponse[this.state.systemName]["magnetic"]["info"]
                    });
                    break;
                }
            case 1:
                {
                    this.props.history.push('/output_acoustic');
                    break;
                }
            case 2:
                {
                    this.props.history.push('/output_seismic');
                    break;
                }
            default:
                break;
        }

    }

    handleChangeWorkingDistance = async (event) => {
        await this.setState({ workingDistance: event.target.value });
        this.handleStartSimulationButtonEnableMagnetic();
    };
    onChangeDistortionValueX = async (event) => {
        const re = /^\d*(\.\d*)?$/;
        if (event.target.value === '' || re.test(event.target.value)) {
            await this.setState({ distortionValueX: event.target.value })
            this.handleStartSimulationButtonEnableMagnetic();
        }
    };
    onChangeFrequencyX = async (event) => {
        const re = /^\d*(\.\d*)?$/;
        if (event.target.value === '' || re.test(event.target.value)) {
            await this.setState({ frequencyX: event.target.value })
            this.handleStartSimulationButtonEnableMagnetic();
        }
    };
    onChangeDistortionValueY = async (event) => {
        const re = /^\d*(\.\d*)?$/;
        if (event.target.value === '' || re.test(event.target.value)) {
            await this.setState({ distortionValueY: event.target.value })
            this.handleStartSimulationButtonEnableMagnetic();
        }
    };
    onChangeFrequencyY = async (event) => {
        const re = /^\d*(\.\d*)?$/;
        if (event.target.value === '' || re.test(event.target.value)) {
            await this.setState({ frequencyY: event.target.value });
            this.handleStartSimulationButtonEnableMagnetic();
        }
    };
    onChangeDistortionValueZ = async (event) => {
        const re = /^\d*(\.\d*)?$/;
        if (event.target.value === '' || re.test(event.target.value)) {
            await this.setState({ distortionValueZ: event.target.value })
            this.handleStartSimulationButtonEnableMagnetic();
        }
    };

    onChangeFrequencyZ = async (event) => {
        const re = /^\d*(\.\d*)?$/;
        if (event.target.value === '' || re.test(event.target.value)) {
            await this.setState({ frequencyZ: event.target.value })
            this.handleStartSimulationButtonEnableMagnetic();
        }
    };

    handleSeismicAxisButtonPressed = async (axis) => {
        if (axis === this.state.axis_type)
            return;
        //this.initializeSeismicFields(this.state.systemName)
        this.setState({ axis_type: axis, inputFieldsSeismic: this.state.seismicAxisAvailable[axis]["inputFields"], imageBytesSeismic: this.state.seismicAxisAvailable[axis]["imageBytes"], seismicErrorMessage: "" })
        this.handleStartSimulationButtonEnableSeismic();
    }
    initializeMagneticFields() {
        this.setState({
            imageBytesMagnetic: "",
            magneticFileInputValue: "",
            frequencyX: 50,
            distortionValueX: "",
            frequencyY: 50,
            distortionValueY: "",
            frequencyZ: 50,
            distortionValueZ: "",
            fieldsCompletedMagnetic: false
        })
    }
    initializeAcousticFields(systemName) {
        let inputFieldsAcoustic = [];
        for (let i = 0; i < Object.keys(this.state.jsonResponse[systemName]["acoustic"]["info"]).length; i++) {
            const currentObject = this.state.jsonResponse[systemName]["acoustic"]["info"]
            [Object.keys(this.state.jsonResponse[systemName]["acoustic"]["info"])[i]];
            const interval = currentObject["start"] + "Hz - " + currentObject["end"] + "Hz";
            inputFieldsAcoustic.push({ "frequency": "", "distortion_value": "", "interval": interval })
        }
        this.setState({ inputFieldsAcoustic, imageBytesAcoustic: "", fieldsCompletedAcoustic: false, acousticFileInputValue: "" })
    }
    initializeSeismicFields(systemName) {
        let inputFieldsSeismic = [];
        let seismicAxisAvailable = {}
        for (let i = 0; i < Object.keys(this.state.jsonResponse[systemName]["seismic"]["info"]).length; i++) {
            const currentObject = this.state.jsonResponse[systemName]["seismic"]["info"]
            [Object.keys(this.state.jsonResponse[systemName]["seismic"]["info"])[i]];
            const interval = currentObject["start"] + "Hz - " + currentObject["end"] + "Hz";
            inputFieldsSeismic.push({ "frequency": "", "distortion_value": "", "interval": interval })
        }
        seismicAxisAvailable = {
            "z": { "available": true, "inputFields": inputFieldsSeismic, "imageBytes": "" },
            "xy": { "available": true, "inputFields": inputFieldsSeismic, "imageBytes": "" },
            "xyz": { "available": true, "inputFields": inputFieldsSeismic, "imageBytes": "" }
        };
        this.setState({
            inputFieldsSeismic, imageBytesSeismic: "", fieldsCompletedSeismic: false, seismicFileInputValue: "", seismicAxisAvailable
        })

    }

    onInputClick(event) {
        event.target.value = null
    }
    render() {
        const { classes } = this.props;

        const handleChangeMicroscope = (event) => {
            let copyArray = [];

            let keysArray = [];
            //we set the tabDisabled values one by one because the order of the dictionary is unreliable

            let tabDisabled = [true, true, true, true];
            tabDisabled[0] = !(this.state.jsonResponse[event.target.value]["magnetic"]["enable"]);
            tabDisabled[1] = !(this.state.jsonResponse[event.target.value]["acoustic"]["enable"]);
            tabDisabled[2] = !(this.state.jsonResponse[event.target.value]["seismic"]["enable"]);
            tabDisabled[3] = !(this.state.jsonResponse[event.target.value]["more_info"]["enable"]);

            //sorting the beam energy values
            if (tabDisabled[0] == false)
                keysArray = Object.keys(this.state.jsonResponse[event.target.value]["magnetic"]["info"]);
            let sortedEnergies = {};
            var len = keysArray.length, i, j, stop = 0;
            for (i = 0; i < len; i++) {
                let smallestValue = keysArray[0].substring(0, keysArray[0].length - 2);
                let smallestKey = keysArray[0];
                let smallestIndex = 0;
                for (j = 0; j < len - stop; j++) {
                    let value = keysArray[j].substring(0, keysArray[j].length - 2);
                    if (parseInt(value) < parseInt(smallestValue)) {
                        smallestValue = value;
                        smallestKey = keysArray[j];
                        smallestIndex = j;
                    }
                }
                keysArray.splice(smallestIndex, 1);
                stop++;
                sortedEnergies[smallestKey] = this.state.jsonResponse[event.target.value][smallestKey];
            }
            for (let key in sortedEnergies) {
                if (sortedEnergies.hasOwnProperty(key)) {
                    copyArray.push(<MenuItem key={key} value={key}>{key}</MenuItem>)
                }
            }
            if (this.state.jsonResponse[event.target.value]["magnetic"]["enable"] === true) {
                this.initializeMagneticFields();
            }
            if (this.state.jsonResponse[event.target.value]["acoustic"]["enable"] === true)
                this.initializeAcousticFields(event.target.value)
            if (this.state.jsonResponse[event.target.value]["seismic"]["enable"] === true)
                this.initializeSeismicFields(event.target.value)
            let selectedTab = this.state.selectedTab;
            if (tabDisabled[this.state.selectedTab] === true) {
                for (let i = 0; i < tabDisabled.length; i++) {
                    if (tabDisabled[i] === false) {
                        selectedTab = i;
                        break;
                    }
                }
            }
            this.setState({
                systemName: event.target.value,
                beamEnergy: '',
                workingDistance: '',
                selectOptionsEnergy: copyArray,
                isTabDisabled: tabDisabled,
                selectedTab,
                inputKey: "",
                inputTXTAcoustic:{},
                inputTXTSeismic:{}
            });

            if (this.state.distortionMethod === "Resonant"){
                this.setState({distortionMethod: "Measured"});
            }

        };


        const handleChangeBeamEnergy = (event) => {
            let copyArray = [];
            for (let key in this.state.jsonResponse[this.state.systemName]["magnetic"]["info"][event.target.value]) {
                if (this.state.jsonResponse[this.state.systemName]["magnetic"]["info"][event.target.value].hasOwnProperty(key)) {
                    copyArray.push(<MenuItem key={key}
                        value={this.state.jsonResponse[this.state.systemName]["magnetic"]["info"][event.target.value][key]}>{this.state.jsonResponse[this.state.systemName]["magnetic"]["info"][event.target.value][key]}</MenuItem>)
                }
            }
            this.setState({
                beamEnergy: event.target.value,
                workingDistance: '',
                selectOptionsDistance: copyArray,
                fieldsCompletedMagnetic: false
            });
        };

        const onChangecustomer_name = (event) => {
            this.setState({ customer_name: event.target.value })
        }

        return <>
            <div className={classes.structure}>
                {this.state.loadingScreen === true && (<div
                style={{
                    top: "50%",
                    left: "50%",
                    marginTop: "-50px",
                    marginLeft: "-100px",
                    position: "absolute",
                    width:"200px",
                    height:"200px"
                }}
              >
                
                <img alt="App logo" src={applicationLogo} style={{
                    width: "80%",
                    height:"80%",
                    position:"absolute",
                    left:"10%",
                    top:"5%"
                }} />

                    <CircularProgress style={{
                        position: "absolute",
                        width:"100%",
                        height:"100%"
                    }} />

            </div>)
                }
                <div className="wrapper" style={{ opacity: this.state.loadingScreen === true ? "0" : "1" }}>
                    <div className="page_header">
                        <div className="page">
                            <Grid container direction="row" justifyContent="center" alignItems="center">
                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} >
                                    <CardHeader
                                        avatar={
                                            <Avatar variant={"square"} aria-label="ripple-simulator" src={applicationLogo}
                                                className={classes.large} />
                                        }
                                        titleTypographyProps={{ variant: "h4", color: 'textPrimary' }}
                                        title={"Ripple Simulator - Input Settings"}
                                    />
                                </Grid>
                            </Grid>
                        </div>
                    </div>
                    <Container>
                        <Grid container direction="row" style={{ width: "100%" }} justifyContent="center" alignItems="flex-start">
                            <Grid item xs={12}>
                                <Typography>The Ripple Simulator Tool will present a digitally simulated image showing the impact various environmental interference sources could have on the quality of the SEM image.</Typography>
                                <Typography>The effects of the various interferences are more visible at selected operating conditions or measured frequencies.</Typography>
                                <Typography>• As the magnification is increased, the effect of the external interference is also magnified and therefor more visible in the image.</Typography>
                                <Typography>• EMF (Electromagnetic fields) has a bigger impact on the electron beam at longer working distances (WD) and at lower beam energies (EHT).</Typography>
                                <Typography>• When acoustic and seismic (floor) vibrations are in sync with the natural frequency of the stage, the interference seen, is typically that of the stage resonating to the interference.</Typography>
                                <Typography>Based on the needs and application conditions of the user of the system, the interference may not show an impact on the images they acquire.</Typography>
                                <Typography>By simulating the approximate working conditions of the system, it is possible to determine if a cancelling system is preferred to attain the quality images expected by the user.</Typography>
                            </Grid>
                        </Grid>
                        <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start">
                            <Grid item sm={6} xs={12}>
                                <h2>Microscope Configuration</h2>
                                <Typography>Select the user's proposed system and their potential closest working conditions. </Typography>
                                <br />
                                <Grid container direction="row" spacing={2} justifyContent="flex-start" alignItems="flex-start">
                                    <Grid item xs={12} md={7}>
                                        <FormControl className="MB23" style={{ marginRight: "10px" }} fullWidth={true}>
                                            <InputLabel id="Input microscope type" className={classes.inputRoot}>System</InputLabel>
                                            <Select labelId="Label microscope"
                                                id="Select microscope"
                                                value={this.state.systemName}
                                                MenuProps={{ classes: { paper: classes.menuPaper } }}
                                                onChange={handleChangeMicroscope}>
                                                {this.state.selectOptionsSystem}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} md={5}>
                                        <FormControl fullWidth={true}>
                                            <TextField id="Customer name" autoComplete="off" label="Customer name" onChange={onChangecustomer_name}
                                                value={this.state.customer_name}
                                                InputLabelProps={{
                                                    classes: { root: classes.inputRoot },
                                                }} />
                                        </FormControl>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Tabs variant="scrollable" scrollButtons="auto" indicatorColor="primary" value={this.state.selectedTab} onChange={this.handleTabChange} style={{ marginTop: "20px" }}>
                            <Tab disabled={this.state.isTabDisabled[0]} label={(<Typography style={{ color: "black", fontSize: "13px", opacity: this.state.isTabDisabled[0] === true ? 0.5 : 1 }}>Magnetic distortion</Typography>)} />
                            <Tab disabled={this.state.isTabDisabled[1]} label={(<Typography style={{ color: "black", fontSize: "13px", opacity: this.state.isTabDisabled[1] === true ? 0.5 : 1 }}>Acoustic distortion</Typography>)} />
                            <Tab disabled={this.state.isTabDisabled[2]} label={(<Typography style={{ color: "black", fontSize: "13px", opacity: this.state.isTabDisabled[2] === true ? 0.5 : 1 }}>Seismic distortion</Typography>)} />
                            <Tab disabled={this.state.isTabDisabled[3]} label={(<Typography style={{ color: "black", fontSize: "13px", opacity: this.state.isTabDisabled[3] === true ? 0.5 : 1 }}>More information</Typography>)} />
                        </Tabs>
                        <Grid container style={{ pointerEvents: this.state.isTabDisabled[0] ? "none" : "", opacity: this.state.isTabDisabled[0] ? "0.5" : "1", display: this.state.selectedTab === 0 ? "block" : "none", marginBottom: "48px" }}>
                            <Grid item container spacing={2} style={{ paddingTop: "15px" }}>
                                <Grid item xs={12} md={4}>
                                    <FormControl className="MB23" fullWidth={true} >
                                        <InputLabel id="Input value of beam energy" className={classes.inputRoot}>Beam
                                            energy</InputLabel>
                                        <Select disabled={this.state.systemName === ""}
                                            labelId="Label beam energy"
                                            id="Select beam energy"
                                            value={this.state.beamEnergy}
                                            MenuProps={{ classes: { paper: classes.menuPaper } }}
                                            onChange={handleChangeBeamEnergy}>
                                            {this.state.selectOptionsEnergy}
                                        </Select>
                                    </FormControl>
                                    <FormControl className="MB15" fullWidth={true}>
                                        <InputLabel id="Input value working distance" className={classes.inputRoot}>Working
                                            distance</InputLabel>
                                        <Select disabled={this.state.beamEnergy === ""}
                                            labelId="Label working distance"
                                            id="Select working distance"
                                            value={this.state.workingDistance}
                                            MenuProps={{ classes: { paper: classes.menuPaper } }}
                                            onChange={this.handleChangeWorkingDistance}>
                                            {this.state.selectOptionsDistance}
                                        </Select>
                                    </FormControl>

                                    <h2>Room Configuration</h2>
                                    <Grid item key={"magneticContainer"} style={{ marginTop: "10px", marginBottom: "10px" }} container direction="column" alignItems="flex-start">
                                        <Typography style={{ fontWeight: "bold" }}>Upload a Spicer Kit acquired site AC magnetic spectrum file (.txt) for analysis and ripple simulation.</Typography>
                                        <form style={{ marginTop: "10px" }}>
                                            <input onClick={this.onInputClick.bind(this)} type="file" key={this.state.inputKey} value={this.state.magneticFileInputValue} accept=".txt" onChange={event => this.processMagneticTxtInputChange(event)} />
                                        </form>
                                        {this.state.magneticErrorMessage !== "" && (
                                            <Typography style={{ color: "red", whiteSpace: 'pre-line' }}>
                                                {this.state.magneticErrorMessage}
                                            </Typography>
                                        )}
                                        <Typography style={{ fontWeight: "bold", marginTop:"10px"}}>Or manually enter/adjust the acquired values in the table below.</Typography>

                                        <Grid container style={{ marginTop: "10px" }}>
                                            <Grid item xs={3}>
                                                <Typography style={{textAlign:"center", justifySelf:"center", marginTop:"15px", marginRight:"5px", fontSize:"18px"}}>X value</Typography>
                                            </Grid>
                                            <Grid spacing={1} container direction="row" item xs={8} md={9}>
                                                <Grid item xs={6}>
                                                    <FormControl fullWidth={true}>
                                                        <TextField id="Select frequency fieldX" label="AC mains frequency"
                                                            variant="outlined" type="number"
                                                            InputLabelProps={{
                                                                classes: { root: classes.inputFieldLabelDistortions }, shrink:true
                                                            }}
                                                            value={this.state.frequencyX} onChange={this.onChangeFrequencyX} />
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <FormControl fullWidth={true}>
                                                        <TextField id="Select magnetic fieldX" label="Measured EM Field (mG)"
                                                            variant="outlined" type="number"
                                                            InputLabelProps={{
                                                                classes: { root: classes.inputFieldLabelDistortions }, shrink:true
                                                            }}
                                                            value={this.state.distortionValueX} onChange={this.onChangeDistortionValueX} />
                                                    </FormControl>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid container style={{ marginTop: "10px" }}>
                                            <Grid item xs={3}>
                                                <Typography style={{textAlign:"center", justifySelf:"center", marginTop:"15px", marginRight:"5px", fontSize:"18px"}}>Y value</Typography>
                                            </Grid>
                                            <Grid spacing={1} container direction="row" item xs={8} md={9}>
                                                <Grid item xs={6}>
                                                    <FormControl fullWidth={true}>
                                                        <TextField id="Select frequency fieldY" label="AC mains frequency"
                                                            variant="outlined" type="number"
                                                            InputLabelProps={{
                                                                classes: { root: classes.inputFieldLabelDistortions }, shrink:true
                                                            }}
                                                            value={this.state.frequencyY} onChange={this.onChangeFrequencyY} />
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <FormControl fullWidth={true}>
                                                        <TextField id="Select magnetic fieldY" label="Measured EM Field (mG)"
                                                            variant="outlined" type="number"
                                                            InputLabelProps={{
                                                                classes: { root: classes.inputFieldLabelDistortions }, shrink:true
                                                            }}
                                                            value={this.state.distortionValueY} onChange={this.onChangeDistortionValueY} />
                                                    </FormControl>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid container style={{ marginTop: "10px" }}>
                                        <Grid item xs={3}>
                                                <Typography style={{textAlign:"center", justifySelf:"center", marginTop:"15px", marginRight:"5px", fontSize:"18px"}}>Z value</Typography>
                                            </Grid>
                                            <Grid spacing={1} container direction="row" item xs={8} md={9}>
                                                <Grid item xs={6}>
                                                    <FormControl fullWidth={true}>
                                                        <TextField id="Select frequency fieldZ" label="AC mains frequency"
                                                            variant="outlined" type="number"
                                                            InputLabelProps={{
                                                                classes: { root: classes.inputFieldLabelDistortions }, shrink:true
                                                            }}
                                                            value={this.state.frequencyZ} onChange={this.onChangeFrequencyZ} />
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <FormControl fullWidth={true}>
                                                        <TextField id="Select magnetic fieldZ" label="Measured EM Field (mG)"
                                                            variant="outlined" type="number"
                                                            InputLabelProps={{
                                                                classes: { root: classes.inputFieldLabelDistortions }, shrink:true
                                                            }}
                                                            value={this.state.distortionValueZ} onChange={this.onChangeDistortionValueZ} />
                                                    </FormControl>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} md={8} direction="column" justifyContent="center" container>
                                    <img src={`data:image/jpeg;base64,${this.state.imageBytesMagnetic}`} style={{ display: this.state.imageBytesMagnetic === "" ? "none" : "", width: "auto", height: "456px"}} />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid container style={{ pointerEvents: this.state.isTabDisabled[1] ? "none" : "", opacity: this.state.isTabDisabled[1] ? "0.5" : "1", display: this.state.selectedTab === 1 ? "block" : "none", marginBottom: "48px" }}>
                            <Grid item container spacing={2} style={{ paddingTop: "15px" }} columns={12}>
                                <Grid item xs={12} md={4}>
                                    <h2>Room Configuration</h2>
                                    <Typography style={{ fontWeight: "bold" }}>Upload a Spicer Kit acquired site acoustic spectrum file (.txt) for analysis and ripple simulation.</Typography>
                                    <form style={{ marginTop: "10px" }}>
                                        <input onClick={this.onInputClick.bind(this)} type="file" key={this.state.inputKey} value={this.state.acousticFileInputValue} accept=".txt" onChange={event => this.processAcousticTxtInputChange(event)} />
                                    </form>
                                    {this.state.acousticErrorMessage !== "" && (
                                        <Typography style={{ color: "red", whiteSpace: 'pre-line' }}>
                                            {this.state.acousticErrorMessage}
                                        </Typography>
                                    )}
                                    <Typography style={{ fontWeight: "bold", marginTop:"10px"}}>Or manually enter/adjust the acquired values in the table below.</Typography>
                                    <Typography style={{ fontWeight: "bold", marginTop: "7.5px" }}>Peak distortion</Typography>
                                    <hr />
                                    <div>
                                        {this.updateInterfaceAcoustic(classes)}
                                    </div>
                                    <Typography style={{ fontWeight: "bold", marginTop: "7.5px" }}>System sensitivity</Typography>
                                    <hr />
                                    <FormControlLabel
                                        style={{marginLeft:1}}
                                        labelPlacement="start"
                                        control={
                                            <Switch
                                                checked={this.state.distortionMethod === "Measured"? true:false}
                                                color="primary"
                                                value="Measured"
                                                onChange={this.handleDistortionMethod}/>}
                                        label={<Typography style={{fontSize:"12px"}}>Measured</Typography>}
                                    />
                                    <FormControlLabel
                                        style={{marginLeft:"7%"}}
                                        labelPlacement="start"
                                        control={
                                            <Switch
                                                checked={this.state.distortionMethod === "Resonant"? true:false}
                                                color="primary"
                                                value="Resonant"
                                                disabled ={ Object.keys(this.state.inputTXTAcoustic).length === 0?true:false}
                                                onChange={this.handleDistortionMethod}/>}
                                        label={<Typography style={{fontSize:"12px"}}>Resonant</Typography>}
                                    />
                                    <FormControlLabel
                                        style={{marginLeft:"7%"}}
                                        labelPlacement="start"
                                        control={
                                            <Switch
                                                checked={this.state.distortionMethod === "Worst case"? true:false}
                                                color="primary"
                                                value="Worst case"
                                                onChange={this.handleDistortionMethod}/>}
                                        label={<Typography style={{fontSize:"12px"}}>Worst case</Typography>}
                                    />
                                </Grid>
                                <Grid item xs={12} md={8} direction="column" justifyContent="center" container>
                                    <img src={`data:image/jpeg;base64,${this.state.imageBytesAcoustic}`} style={{ display: this.state.imageBytesAcoustic === "" ? "none" : "", width: "auto", height: "456px"}} />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid container style={{ pointerEvents: this.state.isTabDisabled[2] ? "none" : "", opacity: this.state.isTabDisabled[2] ? "0.5" : "1", display: this.state.selectedTab === 2 ? "block" : "none", marginBottom: "48px" }}>
                            <Grid item container spacing={2} style={{ paddingTop: "15px" }}>
                                <Grid item xs={12} md={4} container>
                                    <Grid item xs={12}>
                                    </Grid>
                                    <Grid item>
                                        {this.state.seismicFileInputValue === "" ?
                                            <Grid>
                                                <h2>Room Configuration</h2>
                                                <Typography style={{ fontWeight: "bold", marginTop: "15px" }}>Upload a Spicer Kit acquired site X,Y,Z seismic spectrum file (.txt) for analysis and ripple simulation.</Typography>
                                                <form style={{ marginTop: "10px" }}>
                                                    <input onClick={this.onInputClick.bind(this)} value={this.state.seismicFileInputValue} type="file" key={this.state.inputKey} accept=".txt" onChange={event => this.processSeismicTxtInputChange(event)} />
                                                </form>
                                            </Grid>
                                            :
                                            <Button variant="outlined" style={{ marginBottom: "10px" }} onClick={() => this.initializeSeismicFields(this.state.systemName)}>
                                                Reset configuration
                                            </Button>}
                                        {this.state.seismicErrorMessage !== "" && (
                                            <Typography style={{ color: "red", whiteSpace: 'pre-line' }}>
                                                {this.state.seismicErrorMessage}
                                            </Typography>
                                        )}
                                        <Typography style={{ fontWeight: "bold", marginTop:"10px"}}>Or manually enter/adjust the acquired values in the table below.</Typography>
                                        <Typography style={{ marginTop: "10px" }}>Select a simulation axis based on the uploaded spectrum file axis.</Typography>
                                    </Grid>
                                    <Grid container direction="row" spacing={1} alignItems="flex-start">
                                        {this.state.seismicAxisAvailable["xyz"]["available"] === true && (
                                            <Grid item xs={4}>
                                                <Button fullWidth={true} variant={this.state.axis_type === "xyz" ? "contained" : "outlined"} onClick={() => this.handleSeismicAxisButtonPressed("xyz")}>
                                                    X,Y,Z axis
                                                </Button>
                                            </Grid>)}
                                        {this.state.seismicAxisAvailable["xy"]["available"] === true &&
                                            (<Grid item xs={4}>
                                                <Button fullWidth={true} variant={this.state.axis_type === "xy" ? "contained" : "outlined"} onClick={() => this.handleSeismicAxisButtonPressed("xy")}>
                                                    X, Y axis
                                                </Button>
                                            </Grid>)}
                                        {this.state.seismicAxisAvailable["z"]["available"] === true && (
                                            <Grid item xs={4}>
                                                <Button fullWidth={true} variant={this.state.axis_type === "z" ? "contained" : "outlined"} onClick={() => this.handleSeismicAxisButtonPressed("z")}>
                                                    Z axis
                                                </Button>
                                            </Grid>)}
                                    </Grid>
                                    {this.state.axis_type !== "" && (<Grid container>
                                        <Grid item xs={12}>
                                            <Typography style={{ fontWeight: "bold", marginTop: "7.5px" }}>Peak distortion</Typography>
                                            <hr />
                                            {this.updateInterfaceSeismic(classes)}
                                            <Typography style={{ fontWeight: "bold", marginTop: "7.5px" }}>System sensitivity</Typography>
                                            <hr />
                                           <FormControlLabel
                                                style={{marginLeft:1}}
                                                labelPlacement="start"
                                                control={
                                                    <Switch
                                                        checked={this.state.distortionMethod === "Measured"? true:false}
                                                        color="primary"
                                                        value="Measured"
                                                        onChange={this.handleDistortionMethod}/>}
                                                label={<Typography style={{fontSize:"12px"}}>Measured</Typography>}
                                            />
                                           <FormControlLabel
                                                style={{marginLeft:"7%"}}
                                                labelPlacement="start"
                                                control={
                                                    <Switch
                                                        checked={this.state.distortionMethod === "Resonant"? true:false}
                                                        color="primary"
                                                        value="Resonant"
                                                        disabled ={ Object.keys(this.state.inputTXTSeismic).length === 0?true:false}
                                                        onChange={this.handleDistortionMethod}/>}
                                                label={<Typography style={{fontSize:"12px"}}>Resonant</Typography>}
                                           />
                                           <FormControlLabel
                                                style={{marginLeft:"7%"}}
                                                labelPlacement="start"
                                                control={
                                                    <Switch
                                                        checked={this.state.distortionMethod === "Worst case"? true:false}
                                                        color="primary"
                                                        value="Worst case"
                                                        onChange={this.handleDistortionMethod}/>}
                                                label={<Typography style={{fontSize:"12px"}}>Worst case</Typography>}
                                           />
                                        </Grid>
                                    </Grid>)}
                                </Grid>
                                <Grid item xs={12} md={8} direction="column" justifyContent="center" container>
                                    <img src={`data:image/jpeg;base64,${this.state.imageBytesSeismic}`} style={{ display: this.state.imageBytesSeismic === "" ? "none" : "", width: "auto", height: "456px" }} />
                                </Grid>
                            </Grid>
                        </Grid>
                        <div style={{ pointerEvents: this.state.isTabDisabled[3] ? "none" : "", opacity: this.state.isTabDisabled[3] ? "0.5" : "1", display: this.state.selectedTab === 3 ? "block" : "none", marginBottom: "48px" }}>
                            <Grid container>
                                <div>
                                    {this.state.systemName !== "" && !this.state.isTabDisabled[3] && (
                                        <div>{HTML2React(this.state.jsonResponse[this.state.systemName]["more_info"]["info"])}</div>
                                    )}

                                </div>


                            </Grid>

                        </div>
                        <Grid container direction="row" justifyContent="center" alignItems="center">
                            <Grid item xs={12} md={6}>
                                <Button variant="contained" fullWidth={true} color="primary" size={"large"}
                                    disabled={(this.state.selectedTab === 0 && (this.state.fieldsCompletedMagnetic === false || this.state.isTabDisabled[0] === true)) ||
                                        (this.state.selectedTab === 1 && (this.state.fieldsCompletedAcoustic === false || this.state.isTabDisabled[1] === true)) ||
                                        (this.state.selectedTab === 2 && (this.state.fieldsCompletedSeismic === false || this.state.isTabDisabled[2] === true)) ||
                                        (this.state.selectedTab === 3)
                                    }
                                    onClick={this.startSimulation}>
                                    Start Simulation
                                </Button>
                                {
                                    this.admin &&
                                    <Button style={{ marginTop: "10px", marginBottom: "10px" }} variant="contained" color="primary" fullWidth={true} size={"large"} onClick={this.goToAdminPanel}>
                                        Admin Panel
                                    </Button>
                                }
                            </Grid>
                        </Grid>
                        <div style={{ height: "60px" }} />
                    </Container>
                    {/*<div
                    className="footer">
                    <div className="page page400">
                        ZEISS International, &copy; 2021
                    </div>
                </div>*/}
                    <Box sx={{ display: { xs: 'none', md: 'block' } }}>
                        <Button style={{ position: "fixed", bottom: "0", right: "0", marginRight: "2%", marginBottom: "2%", width: "150px" }}
                            href="mailto:techsupport.sem.microscopy@zeiss.com?subject=Ripple%20Simulator%20Feedback&body=Hello!%0D%0AI%20have%20some%20feedback%20about%20the%20app.%0D%0A"
                            variant="contained" fullWidth={true} color="primary" size={"large"}>
                            <Feedback fontSize="large" style={{ marginRight: "5px" }} />
                            Feedback
                        </Button>
                    </Box>
                    <Box sx={{ display: { xs: 'block', md: 'none' } }}>
                        <Button style={{ position: "fixed", bottom: "0", right: "0", marginRight: "2%", marginBottom: "2%", width: "50px" }}
                            href="mailto:techsupport.sem.microscopy@zeiss.com?subject=Ripple%20Simulator%20Feedback&body=Hello!%0D%0AI%20have%20some%20feedback%20about%20the%20app.%0D%0A"
                            variant="contained" fullWidth={true} color="primary" size={"large"}>
                            <Feedback fontSize="large" style={{ marginRight: "5px" }} />
                        </Button>
                    </Box>
                </div>
            </div>
        </>;
    }
}

export default (withStyles(styles)(DashboardContainer));