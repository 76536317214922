import axios from "axios/index";
import { baseAddress } from "../../tools/baseAddress";
let addressUploadVideo = baseAddress + "upload_video";
let addressDeleteVideo = baseAddress + "delete_video";
let addressGetAllVideos = baseAddress + "get_all_videos";

export const handleAcousticCsvInputChange = (event, t) => {
    t.setState({
        selectedCsvAcoustic: event.target.files[0],
        formCsvAcoustic: event.target.value
    })
    return t;
  }
  
export const handleSeismicCsvInputChange = (event, t) => {
  t.setState({
      selectedCsvSeismic: event.target.files[0],
      formCsvSeismic: event.target.value
  })
  return t;
}

export const handleMagneticCsvInputChange = (event, t) => {
  t.setState({
      selectedCsvMagnetic: event.target.files[0],
      formCsvMagnetic: event.target.value
  })
  return t;
}

  export const handleZipInputChange = (event, t) => {
    t.setState({
        selectedZip: event.target.files[0],
    })
    return t;
  } 
  export const handleVideoInputChange = (event, t) => {
    t.setState({
        selectedVideo: event.target.files[0],
    })
    return t;
  }
  
  
  export const goToAdminPanel = (t) => {
    t.props.history.push('/admin');
  }
  
  
  export const toggleLoadingScreenDisplay = (t, loadingScreenDisplay) => {
    t.setState({
        loadingScreen: loadingScreenDisplay
    })
    return t.state;
  }

  export const uploadVideo = (video) => {
    
    var formData = new FormData();
    formData.append("videoFile", video);
    formData.append("token", localStorage.getItem("token"))
    var config = {
      onUploadProgress: function(progressEvent) {
        var percentCompleted = Math.round( (progressEvent.loaded * 100) / progressEvent.total );
      }
    };
    return axios
        .post(addressUploadVideo, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            },config
        })
        .then(response => {
          return response;
        })
        .catch(err => {
            if(err.response===undefined || err.status === 500 || "500" in err.response)
                return {"data":"Internal server error! Please try again."};
            if(err.response.status === 413){
              return{"data": "The file's size cannot exceed 100 MB"};}
            return err.response
        })
}

export const fetchVideoList = () => {
    
  var formData = new FormData();
  formData.append("token", localStorage.getItem("token"))
  return axios
      .post(addressGetAllVideos, formData, {
          headers: {
              'Content-Type': 'multipart/form-data'
          }
      })
      .then(response => {
        return response;
      })
      .catch(err => {
          if(err.response===undefined || err.status === 500 || "500" in err.response)
              return {"data":"Internal server error! Please try again."};
          return err.response
      })
}


export const deleteVideo = (videoName) => {
  return axios
  .post(addressDeleteVideo,
    {
      token: localStorage.getItem("token"),
      video_name: videoName
    })
  .then(response => {
    return response
  })
  .catch(err => {
    return err.response
  })

}

  
  