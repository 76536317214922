import axios from "axios/index";
import {baseAddress} from "../../../tools/baseAddress"
let addressGetAllSystems = baseAddress + "get_all_systems";
let addressSystemChangeEnabled = baseAddress + "system_change_enabled";
let addressDeleteSystem = baseAddress + "delete_system";
export const getAllSystems = () => {
  return axios
    .post(addressGetAllSystems, {
      token: localStorage.getItem("token")
    })
    .then(response => {
      return response
    })
    .catch(err => {
      console.log(err)
    })
}

export const systemChangeEnabled = (info_json) => {


  return axios
    .post(addressSystemChangeEnabled,
      {
        token: localStorage.getItem("token"),
        info: info_json,
      })
    .then(response => {
      return response
    })
    .catch(err => {
      console.log(err)
    })
}

export const deleteSystem = (systemName) => {
  return axios
    .post(addressDeleteSystem,
      {
        token: localStorage.getItem("token"),
        system_key: systemName
      })
    .then(response => {
      return response
    })
    .catch(err => {
      return err.response
    })

}

export const dataDashboard = async () => await axios.post('/api/dashboard', {
  token: localStorage.getItem("token")
});





