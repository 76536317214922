import React from "react";
import { handleAcousticCsvInputChange, handleMagneticCsvInputChange, handleSeismicCsvInputChange, handleZipInputChange, fetchVideoList, goToAdminPanel, deleteVideo } from "../../tools/SystemManagement";
import { editSystem, getSystemInfo, sortObjectByKeys } from "../controller/EditSystemAction";
import { Grid, CardHeader, Tab, Tabs, Container, Box, Dialog, DialogTitle, DialogActions, DialogContent, DialogContentText } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import Button from "@material-ui/core/Button";
import applicationLogo from "../../../design/zeiss-logo-tagline_rgb.png"
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import FolderIcon from '@material-ui/icons/Folder';
import CloudUploadRounded from '@material-ui/icons/CloudUploadRounded';
import FileCopyRounded from '@material-ui/icons/FileCopyRounded';
import DeleteRounded from '@material-ui/icons/DeleteRounded';
import Avatar from '@material-ui/core/Avatar';
import Tooltip from "@material-ui/core/Tooltip";
import Snackbar from "@material-ui/core/Snackbar";
import { uploadVideo } from "../../tools/SystemManagement";
import { WebBuilder, editor } from "../../tools/WebBuilder";
import { leaveConfirmVideoProcessing } from "../../../tools/constantMessages";

class AdminPanelEditSystemContainer extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedCsvAcoustic: "",
            selectedCsvSeismic: "",
            selectedCsvMagnetic: "",
            selectedZip: "",
            selectedVideo: "",
            errorMessage: "",
            inputKey: Date.now(),
            loadingScreen: false,
            systemName: localStorage.getItem("editingSystemName"),
            selectedTab: 0,
            isTabDisabled: [true, true, true],
            editorHtml: null,
            editorJson: null,
            moreInfoChanged: false,
            systemNotFound: false,
            snackBarOpen: false,
            videoLinks: [],
            uploadMouseHover: false,
            videosProcessing: false,
        };
        this.handleEditSystem = this.handleEditSystem.bind(this);
        this.handleGetSystemInfo = this.handleGetSystemInfo.bind(this);
        this.handleGetSystemInfo(this.state.systemName);

    }
    componentDidMount() {
        this.populateVideoLinks();
    }


    handleEditSystem = async (systemName, csvMagnetic, csvAcoustic, csvSeismic, zip) => {
        this.setState({ toggleLoadingScreenDisplay: true });
        if (this.state.moreInfoChanged === true)
            await this.setState({ editorHtml: editor.runCommand('gjs-get-inlined-html'), editorJson: editor.getProjectData() })

        let response = await editSystem(systemName, csvMagnetic, csvAcoustic, csvSeismic, zip, this.state.editorHtml, this.state.editorJson);
        this.setState({ toggleLoadingScreenDisplay: false });
        const isString = typeof response.data === "string" || response.data instanceof String;
        if (!isString) {
            if ('redirect' in response.data) {
                this.props.history.push('/login')
            }
            let problemsFound = "";
            for (const [key, value] of Object.entries(response.data)) {
                problemsFound += (key + ":\n")
                let index;
                for (index = 0; index < Object.values(value).length; index++) {
                    problemsFound += ("- " + Object.values(value)[index] + '\n');
                }
            }
            this.setState({
                errorMessage: problemsFound,
                inputKey: Date.now(),
                selectedCsvAcoustic: "",
                selectedCsvSeismic: "",
                selectedCsvMagnetic: "",
                selectedZip: "",
            })
        }
        else if (response.data === "The system was edited successfully.")
            goToAdminPanel(this);
        else this.setState({
            errorMessage: response['data'],
            selectedCsv: "",
            selectedZip: "",
            inputKey: Date.now()
        })
    }

    populateVideoLinks = async () => {
        this.setState({ videosProcessing: true});
        let response = await fetchVideoList();
        if (response.status !== 200)
        {
            this.setState({ videosProcessing: false});
            return;
        }
        let videoLinks = [];
        response.data.videos.forEach(element => {
            videoLinks.push(
                <Grid item style={{ border: '1px solid', borderRadius: '5px', marginBottom: "5px", marginTop: "5px" }} container direction="row">
                    <Grid item xs={11}>
                        <Typography noWrap style={{ marginTop: 8, marginLeft: 5 }}>
                            {element}
                        </Typography>

                    </Grid>
                    <Grid item container direction="row" xs={1} style={{ padding: 0, paddingRight: 10 }}>
                        <Grid item xs={6}>
                            <Button onClick={() => {
                                navigator.clipboard.writeText(element);
                                this.setState({ snackBarOpen: true });
                            }}>
                                <Tooltip title="Copy video link">
                                    <FileCopyRounded />
                                </Tooltip>
                            </Button>
                        </Grid>
                        <Grid item xs={6}>
                            <Button style={{ marginRight: 0 }}>
                                <Tooltip title="Delete video">
                                    <DeleteRounded style={{ color: "red" }} onClick={() => { this.handleDeleteVideo(element); }} />
                                </Tooltip>
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            )
        }
        );

        this.setState({ "videoLinks": videoLinks, videosProcessing: false })
    }


    handleGetSystemInfo = async (systemName) => {
        let response = await getSystemInfo(systemName);

        if ('redirect' in response) {
            this.props.history.push('/login')
        }
        if (response.status === 400) {
            this.setState({ systemNotFound: true });
            return;
        }
        var magneticTable = [];
        var acousticTable = [];
        var seismicTable = [];
        var imagesTable = [];
        //we sort the beam int values first
        response.data["magnetic"] = sortObjectByKeys(Object.keys(response.data["magnetic"]), response.data["magnetic"]);
        const orderedKeysAcoustic = Object.keys(response.data["acoustic"]).sort((a, b) => a.localeCompare(b, undefined, { numeric: true }))

        //then we sort the generated images' beam energies
        response.data.generated_images = sortObjectByKeys(
            Object.keys(response.data.generated_images),
            response.data.generated_images,
            Object.keys(response.data.generated_images).length)


        let index;
        //if the seismic response from server doesn't just contain "enable" and "available"
        if (Object.keys(response.data["seismic"]).length > 2) {
            var seismicTableHeader = [<td key={"titleSystemName"}><b>System name</b></td>, <td key={"titleFreq"}><b>Frequency (Hz)</b></td>];
            index = 0
            for (const entry in Object.entries(response.data["seismic"])[0][1]) {
                seismicTableHeader.push(<td key={entry + index}><b>{entry}</b></td>);
                index++;
            }
            seismicTable.push(
                <thead key={"tableHead"}>
                    <tr key={"trHeadSeismic"}>
                        {seismicTableHeader}
                    </tr>
                </thead>
            )
            index = 0;
            for (const [key, value] of Object.entries(response.data["seismic"])) {
                if (key === "enable" || key === "available")
                    continue;
                var innerSeismicTable = [<td key={"systemName" + index}>{systemName}</td>, <td key={"key" + index}>{key}</td>]
                let innerIndex = 0;
                for (const innerValue of Object.values(value)) {
                    innerSeismicTable.push(<td key={"innerVal" + innerIndex}>{innerValue}</td>)
                    innerIndex++;
                }
                seismicTable.push(
                    <tbody key={"innerWrapper" + index}>
                        <tr key={index}>
                            {innerSeismicTable}
                        </tr>
                    </tbody>
                )
                index++
            }

        }
        imagesTable.push(<thead key={"tHeadImages"}>
            <tr key={"tHeadTrImages"}>
                <th key={"beamEnergy"}>
                    Beam energy
                </th>
                <th key={"sourceImage"}>
                    Source image
                </th>
                <th key={"magnification"}>
                    Magnification of the generated images
                </th>
            </tr>
        </thead>)
        //if the acoustic response from server doesn't just contain "enable" and "available"
        if (orderedKeysAcoustic.length > 2) {
            index = 0
            var acousticTableHeader = [<td key={"sysNameTitle"}><b>System name</b></td>, <td key={"frequencyTitle"}><b>Frequency (Hz)</b></td>];
            for (const entry in Object.entries(response.data["acoustic"])[0][1]) {
                acousticTableHeader.push(<td key={index + "td"}><b key={index + "b"}>{entry}</b></td>);
                index++;
            }
            acousticTable.push(
                <thead key={systemName + "HeadAcoustic" + index}>
                    <tr key={systemName + "trAcoustic" + index}>
                        {acousticTableHeader}
                    </tr>
                </thead>
            )
            index = 1;
            for (let key of orderedKeysAcoustic) {
                if (key === "enable" || key === "available")
                    continue;
                const value = response.data["acoustic"][key];
                acousticTable.push(
                    <tbody key={index}>
                        <tr key={index}>
                            <td key={"sysName" + index}>
                                {systemName}
                            </td>
                            <td key={"key" + index}>
                                {key}
                            </td>
                            <td key={"sens" + index}>
                                {value["Sensitivity"]}
                            </td>
                            <td key={"threshold" + index}>
                                {value["Threshold"]}
                            </td>
                        </tr>
                    </tbody>
                )
                index++;
            }
        }
        //if the magnetic response from server doesn't just contain "enable" and "available"

        if (Object.keys(response.data["magnetic"]).length > 2) {

            index = 0;
            magneticTable.push(
                <thead key={"magneticHead"}>
                    <tr key={"magneticTrHead"}>
                        <th key={"titleSysName"}>
                            System name
                        </th>
                        <th key={"titleBeamInt"}>
                            Beam int
                        </th>
                        <th key={"titleBWD"}>
                            WD
                        </th>
                        <th key={"titlemG_x"}>
                            nm/mG_x
                        </th>
                        <th key={"title_y"}>
                            nm/mG_y
                        </th>
                        <th key={"title_z"}>
                            nm/mG_z
                        </th>
                    </tr>
                </thead>
            )
            for (const [key, value] of Object.entries(response.data["magnetic"])) {
                if (key === "enable" || key === "available")
                    continue;
                for (const [innerKey, innerValue] of Object.entries(value)) {
                    magneticTable.push(
                        <tbody key={"body" + index}>
                            <tr key={index}>
                                <td key={"systemName" + index}>
                                    {systemName}
                                </td>
                                <td key={"key" + index}>
                                    {key}
                                </td>
                                <td key={"innerKey" + index}>
                                    {innerKey}
                                </td>
                                <td key={"innerValueX" + index}>
                                    {innerValue["nm/mG_x"]}
                                </td>
                                <td key={"innerValueY" + index}>
                                    {innerValue["nm/mG_y"]}
                                </td>
                                <td key={"innerValueZ" + index}>
                                    {innerValue["nm/mG_z"]}
                                </td>
                            </tr>
                        </tbody>
                    )
                    index++;
                }
            }
        }

        response.data.seismic = sortObjectByKeys(
            Object.keys(response.data.seismic),
            response.data.seismic)

        let tableValues = [];
        for (const [key, value] of Object.entries(response.data.generated_images)) {
            let index = 0;
            let bigIndex = 0;
            let magnifications = [];
            for (bigIndex = 0; bigIndex < Object.values(value).length; bigIndex++) {
                let currentMagnifications = "";
                for (index = 0; index < Object.values(value)[bigIndex].length; index++) {
                    currentMagnifications += Object.values(value)[bigIndex][index] + ", ";
                }
                currentMagnifications = currentMagnifications.substring(0, currentMagnifications.length - 2);
                magnifications.push(currentMagnifications);
            }
            for (bigIndex = 0; bigIndex < Object.values(value).length; bigIndex++) {
                if (bigIndex === 0) {
                    tableValues.push(
                        <tr key={bigIndex + key}>
                            <td key={bigIndex + ", 0"} rowSpan={Object.values(value).length.toString()}>
                                {key}
                            </td>
                            <td key={bigIndex + ", 1"}>
                                {Object.keys(value)[0]}
                            </td>
                            <td key={bigIndex + ", 2"}>
                                {magnifications[0]}
                            </td>
                        </tr>
                    )
                    continue;
                }
                tableValues.push(
                    <tr key={bigIndex + key}>
                        <td key={bigIndex + ", 0"}>
                            {Object.keys(value)[bigIndex]}
                        </td>
                        <td key={bigIndex + ", 1"}>
                            {magnifications[bigIndex]}
                        </td>
                    </tr>
                )
            }

        }
        imagesTable.push(
            <tbody key={"tableBody"}>
                {tableValues}
            </tbody>
        )

        this.setState({
            magneticTable: magneticTable,
            acousticTable: acousticTable,
            imagesTable: imagesTable,
            seismicTable: seismicTable,
            isTabDisabled: [!response.data["magnetic"]["enable"], !response.data["acoustic"]["enable"], !response.data["seismic"]["enable"]],
        })
        editor.once('update', () => {
            this.setState({ moreInfoChanged: true })})
            
        if (response.data["more_info"]["available"] === true)
            editor.loadProjectData(JSON.parse(response.data["more_info"]["project"]))
        
    }

    handleTabChange = (event, value) => {
        this.setState({ selectedTab: value });
    }
    handleMouseEnterUpload = () => {
        this.setState({ uploadMouseHover: true });
    }
    handleMouseLeaveUpload = () => {
        this.setState({ uploadMouseHover: false });
    }
    handleUploadVideo = async (event) => {
        this.setState({ videosProcessing: true, errorMessage: "" })
        let response = await uploadVideo(event.target.files[0])
        if (response.data.redirect !== undefined)
            this.props.history.push('/login')

        if (response.status === 200) {
            this.populateVideoLinks();
        }
        else
            this.setState({ errorMessage: response.data })
        this.setState({ videosProcessing: false })
    }
    handleDeleteVideo = async (videoName) => {
        this.setState({ videosProcessing: true, errorMessage: "" })
        let split = videoName.split("/");
        videoName = split[split.length - 1];
        let response = await deleteVideo(videoName)
        if (response.status === 200) {
            this.populateVideoLinks();
        }
        this.setState({ videosProcessing: false })
    }
    render() {
        return <>
            <Container>
                {this.state.toggleLoadingScreenDisplay === true ? <div
                    style={{
                        top: "50%",
                        left: "50%",
                        marginTop: "-50px",
                        marginLeft: "-100px",
                        position: "absolute",
                        width: "200px",
                        height: "200px"
                    }}
                >

                    <img alt="App logo" src={applicationLogo} style={{
                        width: "80%",
                        height: "80%",
                        position: "absolute",
                        left: "10%",
                        top: "5%"
                    }} />

                    <CircularProgress style={{
                        position: "absolute",
                        width: "100%",
                        height: "100%"
                    }} />

                </div> :
                    <Grid container direction="column">
                        <Dialog
                            open={this.state.systemNotFound === true}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                        >
                            <DialogTitle id="alert-dialog-title">
                                {"Error!"}
                            </DialogTitle>
                            <DialogContent>
                                <DialogContentText id="alert-dialog-description">
                                    {"The system does not exist!"}
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={goToAdminPanel.bind(this, this)}>Ok</Button>
                            </DialogActions>
                        </Dialog>
                        <Grid container className="page_header">
                            <Grid className="page">
                                <Grid container direction="row" justifyContent="center" alignItems="center">
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <CardHeader
                                            avatar={
                                                <Avatar variant={"square"} aria-label="ripple-simulator" src={applicationLogo}
                                                    style={{
                                                        width: "80px",
                                                        height: "80px",
                                                    }
                                                    } />
                                            }
                                            titleTypographyProps={{ variant: "h4", color: 'textPrimary' }}
                                            title={"Ripple Simulator - System Editor"}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid container direction="column">
                            <Grid container spacing={6}>
                                <Grid item>
                                    <Typography style={{ fontSize: 30 }}>
                                        {this.state.systemName}
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Typography key={"currentSettings"} style={{ fontSize: 30 }}>
                                        CURRENT SETTINGS
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Typography>
                                <FolderIcon />
                                {" "}System folder name: /{this.state.systemName}
                            </Typography>
                            <Grid container spacing={2} style={{ marginTop: "25px" }}>
                                <Grid item>
                                    <Button variant="outlined" onClick={this.handleEditSystem.bind(this, this.state.systemName, this.state.selectedCsvMagnetic, this.state.selectedCsvAcoustic, this.state.selectedCsvSeismic, this.state.selectedZip)}
                                        disabled={this.state.selectedCsvAcoustic === "" && this.state.selectedCsvMagnetic === "" && this.state.selectedCsvSeismic === "" && this.state.selectedZip === "" && this.state.moreInfoChanged === false}>
                                        Update System
                                    </Button>
                                </Grid>
                                <Grid item>
                                    <Button variant="outlined" onClick={goToAdminPanel.bind(this, this)}>
                                        Back to admin panel
                                    </Button>
                                </Grid>
                            </Grid>
                            <Typography style={{ color: "red", marginTop: "20px", whiteSpace: 'pre-line' }}>
                                {this.state.errorMessage}
                            </Typography>
                            <Grid container spacing={10}>
                                <Grid item xs={12} md={7}>

                                    <Tabs indicatorColor="primary" value={this.state.selectedTab} onChange={this.handleTabChange} style={{ width: "max-content" }}>
                                        <Tab label={(<Typography style={{ fontSize: "13px" }}>Magnetic distortion</Typography>)} />
                                        <Tab label={(<Typography style={{ fontSize: "13px" }}>Acoustic distortion</Typography>)} />
                                        <Tab label={(<Typography style={{ fontSize: "13px" }}>Seismic distortion</Typography>)} />
                                        <Tab label={(<Typography style={{ fontSize: "13px" }}>More information</Typography>)} />
                                    </Tabs>
                                    <Grid container style={{ display: this.state.selectedTab === 0 ? "block" : "none" }}>
                                        <Typography style={{ marginTop: 20 }}>
                                            <b>
                                                CSV table for magnetic sensitivity
                                            </b>
                                        </Typography>
                                        <form>
                                            <input type="file" name="file" key={this.state.inputKey} onChange={event => this.state = handleMagneticCsvInputChange(event, this)} accept=".csv" />
                                        </form>
                                        <div>
                                            {this.state.magneticTable !== undefined && this.state.magneticTable.length > 0 &&
                                                <Typography style={{ fontWeight: "bold", marginBottom: "10px", marginTop: "20px" }}>
                                                    Distortion values
                                                </Typography>}
                                            <table>
                                                {this.state.magneticTable}
                                            </table>
                                        </div>
                                    </Grid>
                                    <Grid style={{ display: this.state.selectedTab === 1 ? "block" : "none", paddingBottom: "0px" }}>
                                        <Typography style={{ marginTop: 20 }}>
                                            <b>
                                                CSV table for acoustic sensitivity
                                            </b>
                                        </Typography>
                                        <form>
                                            <input type="file" name="file" key={this.state.inputKey} onChange={event => this.state = handleAcousticCsvInputChange(event, this)} accept=".csv" />
                                        </form>
                                        {this.state.acousticTable !== undefined && this.state.acousticTable.length > 0 &&
                                            <Typography style={{ fontWeight: "bold", marginBottom: "10px", marginTop: "20px" }}>
                                                Distortion values
                                            </Typography>}
                                        <table>
                                            {this.state.acousticTable}
                                        </table>
                                    </Grid>
                                    <Grid style={{ display: this.state.selectedTab === 2 ? "block" : "none", paddingBottom: "0px" }}>
                                        <Typography style={{ fontWeight: "bold", marginTop: "20px" }}>
                                            CSV table for seismic sensitivity
                                        </Typography>
                                        <form>
                                            <input type="file" name="file" key={this.state.inputKey} onChange={event => this.state = handleSeismicCsvInputChange(event, this)} accept=".csv" />
                                        </form>

                                        {this.state.seismicTable !== undefined && this.state.seismicTable.length > 0 &&
                                            <Typography style={{ fontWeight: "bold", marginBottom: "10px", marginTop: "20px" }}>
                                                Distortion values
                                            </Typography>}
                                        <table>
                                            {this.state.seismicTable}
                                        </table>
                                    </Grid>
                                    <Grid style={{ display: this.state.selectedTab === 3 ? "block" : "none", width: "100%" }}>
                                        {this.state.videosProcessing === true ? (
                                            <CircularProgress />) :
                                            (<Grid>
                                                <Grid item style={{ marginTop: "10px", marginBottom: "10px", width: 150 }}>
                                                    <div onMouseEnter={this.handleMouseEnterUpload}
                                                        onMouseLeave={this.handleMouseLeaveUpload}>
                                                        <label htmlFor="formId">
                                                            <input name="" onChange={event => this.handleUploadVideo(event)} accept=".mp4" key={this.state.uploadedVideoKey}
                                                                type="file" id="formId" hidden />

                                                            <Grid style={{ borderRadius: '5px', padding: 10, paddingTop: 15, backgroundColor: this.state.uploadMouseHover ? "#141e8c" : "#3f51b5" }} container direction="row">
                                                                <Grid item>
                                                                    <CloudUploadRounded style={{ color: "white" }} />
                                                                </Grid>
                                                                <Grid item>
                                                                    <Typography style={{ marginLeft: 5, color: "white" }}>
                                                                        Upload video
                                                                    </Typography>
                                                                </Grid>

                                                            </Grid>

                                                            <Snackbar
                                                                open={this.state.snackBarOpen}
                                                                autoHideDuration={2000}
                                                                onClose={() => this.setState({ snackBarOpen: false })}
                                                                message="Video link copied"
                                                            >
                                                            </Snackbar>
                                                        </label>
                                                    </div>
                                                </Grid>
                                                {
                                                    this.state.videoLinks.length > 0 &&
                                                    (<Grid item style={{ maxHeight: "250px", overflowY: "scroll", overflowX: "hidden", border: '1px solid', borderRadius: '10px', padding: 10, width: "1150px", marginTop: "20px", marginBottom: "20px" }}>
                                                        <Grid container>
                                                            {this.state.videoLinks}
                                                        </Grid>
                                                    </Grid>)}
                                            </Grid>
                                            )}
                                        <WebBuilder />
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} md={5}>
                                    {this.state.selectedTab !== 3 && (
                                        <Grid>
                                            <Box sx={{ display: { xs: 'none', sm: 'none', md: 'none', lg: 'block' } }}>
                                                <Typography style={{ marginTop: 50 }}>
                                                    <b>
                                                        Images
                                                    </b>
                                                </Typography>
                                            </Box>
                                            <Box sx={{ display: { xs: 'block', sm: 'block', md: 'block', lg: 'none' } }}>
                                                <Typography>
                                                    <b>
                                                        Images
                                                    </b>
                                                </Typography>
                                            </Box>
                                            <form>
                                                <input type="file" key={this.state.inputKey} onChange={event => this.state = handleZipInputChange(event, this)} accept=".zip" />
                                            </form>
                                            <Typography key={"magnifications"} style={{ fontWeight: "bold", marginBottom: "10px", marginTop: "20px" }}>
                                                Available magnifications
                                            </Typography>
                                            <table key={"imagesTable"}>
                                                {this.state.imagesTable}
                                            </table>
                                        </Grid>)}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                }
            </Container>
        </>;
    }
}
export default withRouter(AdminPanelEditSystemContainer);