import React from "react";
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { deleteSystem, getAllSystems, systemChangeEnabled } from "../controller/AdminPanelAction";
import { Grid, CardHeader, Switch, Container, Dialog, DialogTitle, DialogActions, DialogContent, DialogContentText } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import Button from "@material-ui/core/Button";
import Avatar from '@material-ui/core/Avatar';
import applicationLogo from "../../../design/zeiss-logo-tagline_rgb.png"
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import FormControlLabel from "@material-ui/core/FormControlLabel";
import AdminListContainer from "../../adminlist/container/AdminListContainer";
import Checkbox from '@material-ui/core/Checkbox';

const styles = theme => ({
    large: {
        backgroundColor: 'white',
        width: theme.spacing(10),
        height: theme.spacing(10),
    }

});

class AdminPanelContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            jsonResponse: {},
            systemName: "",
            adminEditingMode: false,
            deletingSystem: null,
            superAdmin: false,
            usingAzureStorage: false,
            deletingSystemError: null,
            gettingAllSystems: false
        };
        this.toggleSystemEditingMode = this.toggleSystemEditingMode.bind(this);
        this.toggleNewSystemMode = this.toggleNewSystemMode.bind(this);
        this.handleSwitchChange = this.handleSwitchChange.bind(this);
        this.handleCheckBoxChange = this.handleCheckBoxChange.bind(this);
    }

    changeDeletingSystemState(system) {
        this.setState({
            deletingSystem: system
        });
        if(system===null)
        this.setState({deletingSystemError:null})
    }

    toggleNewSystemMode() {
        this.setState({
            newSystemMode: !this.state.newSystemMode,
            systemName: ''
        });
    }

    toggleSystemEditingMode() {
        this.setState({ creatingOrEditingSystem: 0 })
        this.setState({ systemEditingMode: !this.state.systemEditingMode });
    }

    toggleAdminEditingMode() {
        this.setState({ adminEditingMode: !this.state.adminEditingMode });
    }

    componentDidMount() {
        this.setState({gettingAllSystems: true})
        getAllSystems().then(({ data: response }) => {
            if ('redirect' in response)
                this.props.history.push('/login')
            this.setState({ superAdmin: response.is_super_admin, usingAzureStorage: response.using_azure_storage });
            delete response.is_super_admin;
            delete response.using_azure_storage;
            this.setState({ jsonResponse: response });
        });
        this.setState({gettingAllSystems: false})
    }


    handleCsvInputChange(event) {
        this.setState({
            selectedCsv: event.target.files[0],
        })
    }


    handleZipInputChange(event) {
        this.setState({
            selectedZip: event.target.files[0],
        })
    }

    handleSwitchChange = (sysName, val) => {
        this.setState(prevState => {
            let jsonResponse = Object.assign({}, prevState.jsonResponse);  // creating copy of state variable jasper
            jsonResponse[sysName]['enable'] = val;                         // update the name property, assign a new value
            return { jsonResponse };                                       // return new object jasper object
        }, () => { this.updateInterface() })
    }

    handleCheckBoxChange = (distortion, sysName, val) => {
        this.setState(prevState => {
            let jsonResponse = Object.assign({}, prevState.jsonResponse);          // creating copy of state variable jasper
            jsonResponse[sysName][distortion]['enable'] = val;                     // update the name property, assign a new value
            return { jsonResponse };                                               // return new object jasper object
        }, () => { this.updateInterface() })
    }

    handleDeleteSystem = async (sysName) => {
        let response = await deleteSystem(sysName);

        if ('redirect' in response) {
            this.props.history.push('/login')
        }
        if (response.status == 200)
            window.location.reload();
        else this.setState({deletingSystemError:response.data.KeyError})
    }

    updateInterface = async () => {
        this.setState({gettingAllSystems: true})
        let response = await systemChangeEnabled(this.state.jsonResponse);
        this.setState({gettingAllSystems: false})
        if ('redirect' in response) {
            this.props.history.push('/login')
        }
        if (response.data == "OK")
            window.location.reload();
    }

    goBackToInputPage() {
        this.props.history.push('/inputs');
    }

    goToNewSystem() {
        localStorage.setItem("usingAzureStorage", this.state.usingAzureStorage)
        this.props.history.push('/new_system');
    }

    goToEditSystem(sysName) {
        localStorage.setItem("editingSystemName", sysName);
        this.props.history.push('/edit_system');
    }

    render() {
        const { classes } = this.props;
        var systemList = [];
        let index = 0;
        for (const [key, value] of Object.entries(this.state.jsonResponse)) {
            systemList.push(
                <Grid item container key={key} style={{ marginBottom: "20px" }}><FormControlLabel
                    control={<Switch color="primary" defaultChecked={value.enable == true} onChange={this.handleSwitchChange.bind(this, key, !value.enable)} />}
                    label={<span style={{ fontWeight: 'bold' }}>{key}</span>}
                    labelPlacement="end" />
                    <Grid item container key={"checkboxes" + index} style={{ display: 'flex', marginLeft: '50px', marginBottom: '10px' }} spacing={0}>
                        <Grid item sm={6} md={2}>
                            <FormControlLabel
                                control={<Checkbox color="primary" checked={value["magnetic"]["enable"]} />}
                                label="Magnetic" onChange={this.handleCheckBoxChange.bind(this, 'magnetic', key, !value["magnetic"]["enable"])}
                                disabled={value["magnetic"]["available"] == false}
                            />
                        </Grid>
                        <Grid item sm={6} md={2}>
                            <FormControlLabel
                                control={<Checkbox color="primary" checked={value["acoustic"]["enable"]} />}
                                label="Acoustic" onChange={this.handleCheckBoxChange.bind(this, 'acoustic', key, !value["acoustic"]["enable"])}
                                disabled={value["acoustic"]["available"] == false}
                            />
                        </Grid>
                        <Grid item sm={6} md={2}>
                            <FormControlLabel
                                control={<Checkbox color="primary" checked={value["seismic"]["enable"]} />}
                                label="Seismic" onChange={this.handleCheckBoxChange.bind(this, 'seismic', key, !value["seismic"]["enable"])}
                                disabled={value["seismic"]["available"] == false}
                            />
                        </Grid>
                        <Grid item sm={6} md={3}>
                            <FormControlLabel
                                control={<Checkbox color="primary" checked={value["more_info"]["enable"]} />}
                                label="More information" onChange={this.handleCheckBoxChange.bind(this, 'more_info', key, !value["more_info"]["enable"])}
                                disabled={value["more_info"]["available"] == false}
                            />
                        </Grid>
                    </Grid>
                    <div key={"buttons" + index} style={{
                        display: 'flex'
                    }}>
                        <Button variant="outlined" style={{ fontWeight: "bold", marginRight: 10 }} size="small" onClick={this.goToEditSystem.bind(this, key)}>
                            Edit system
                        </Button>
                        <Button size="small" onClick={this.changeDeletingSystemState.bind(this, key)}>
                            Delete system
                        </Button>
                    </div>
                </Grid>);

        }
        const horizontal = {
            display: 'flex',
            alignItems: 'flex-start',
            width: 1250
        };
        return <>
            {this.state.pic == "" ? <div
                style={{
                    top: "50%",
                    left: "50%",
                    marginTop: "-50px",
                    marginLeft: "-100px",
                    position: "absolute",
                    width:"200px",
                    height:"200px"
                }}
              >
                
                <img alt="App logo" src={applicationLogo} style={{
                    width: "80%",
                    height:"80%",
                    position:"absolute",
                    left:"10%",
                    top:"5%"
                }} />

                    <CircularProgress style={{
                        position: "absolute",
                        width:"100%",
                        height:"100%"
                    }} />

            </div> :
                <Container>
                    <Dialog
                        open={this.state.deletingSystem!==null}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle id="alert-dialog-title">
                            {"Deleting a system"}
                        </DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                {"Are you sure you want to delete " + this.state.deletingSystem + "?"}
                            </DialogContentText>
                            {this.state.deletingSystemError!==null && 
                            <DialogContentText style={{color: "red"}} id="alert-dialog-error">
                                {this.state.deletingSystemError}
                            </DialogContentText>
                            }
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={this.changeDeletingSystemState.bind(this, null)}>No</Button>
                            <Button onClick={this.handleDeleteSystem.bind(this, this.state.deletingSystem)} autoFocus>
                                Yes
                            </Button>
                        </DialogActions>
                    </Dialog>
                    <Grid container className="page_header">
                        <Grid className="page">
                            <Grid container direction="row" justifyContent="center" alignItems="center">
                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <CardHeader
                                        avatar={
                                            <Avatar variant={"square"} aria-label="ripple-simulator" src={applicationLogo}
                                                style={{
                                                    width: "80px",
                                                    height: "80px",
                                                }
                                                } />
                                        }
                                        titleTypographyProps={{ variant: "h4", color: 'textPrimary' }}
                                        title={"Ripple Simulator - Management Panel"}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid>
                        {!this.state.adminEditingMode &&
                            <Grid container direction="row">
                                <Grid item xs={12} sm={6} md={8}>
                                    <h2 key={"systemsTitle"}>
                                        SYSTEMS
                                    </h2>
                                    {this.state.gettingAllSystems ?
                                    <CircularProgress/>
                                    :
                                    <Grid container key={"systemListTitle"}>
                                        {systemList}
                                    </Grid>
                                    }
                                </Grid>
                                <Grid item xs={12} sm={6} md={4}>
                                    <h2>
                                        MORE ACTIONS
                                    </h2>
                                    <Button onClick={this.goToNewSystem.bind(this)} variant="contained" size="small">
                                        Add System
                                    </Button>
                                    <br></br>
                                    {this.state.superAdmin &&
                                        <div>
                                            <Button style={{ marginTop: "25px" }} variant="contained" size="small" onClick={this.toggleAdminEditingMode.bind(this)}>
                                                Edit admin list
                                            </Button>
                                            <br></br>
                                        </div>
                                    }
                                    <Button style={{ marginTop: "25px" }} variant="contained" size="small" onClick={this.goBackToInputPage.bind(this)}>
                                        Back to input page
                                    </Button>
                                </Grid>
                            </Grid>}
                        {this.state.adminEditingMode &&
                            <AdminListContainer />}
                    </Grid>
                    <div className="footer">
                        <div className="page">
                            ZEISS International, &copy; 2021
                        </div>
                    </div>
                </Container>}
        </>;
    }
}
export default withRouter(withStyles(styles)(AdminPanelContainer));