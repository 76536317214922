import axios from "axios/index";
import { baseAddress } from "../../../tools/baseAddress";
let addressGetSystemInfo = baseAddress + "get_system_info_for_table";
let addressEditSystem = baseAddress + "edit_system";

export const editSystem = (systemName, csvMagnetic, csvAcoustic, csvSeismic, archive, moreInfo, moreInfoProject) => {
    var formData = new FormData();
    formData.append("magneticCsv", csvMagnetic);
    formData.append("acousticCsv", csvAcoustic);
    formData.append("seismicCsv", csvSeismic);
    formData.append("archive", archive);
    formData.append("token", localStorage.getItem("token"))
    formData.append("system_key", systemName)
    if (moreInfo !== null) {
        formData.append("moreInfo", moreInfo)
        formData.append("moreInfoProject", JSON.stringify(moreInfoProject))
    }
    return axios
        .post(addressEditSystem, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
        .then(response => {
            return response
        })
        .catch(err => {
            if(err.response===undefined || err.status === 500 || "500" in err.response)
                return {"data":"Internal server error! Please try again."};
            return err.response
        })
}



export const sortObjectByKeys = (keysArray, object) => {
    let sortedKeys = {}, i, j, stop = 0;
    const len = keysArray.length;
    for (i = 0; i < len; i++) {
        let smallestIndex = 0,
            smallestKey = keysArray[0],
            smallestValue = keysArray[0].substring(0, keysArray[0].length - 2);
        for (j = 0; j < len - stop; j++) {
            let value = keysArray[j].substring(0, keysArray[j].length - 2);
            if (parseInt(value) < parseInt(smallestValue)) {
                smallestIndex = j;
                smallestKey = keysArray[j];
                smallestValue = value;
            }
        }
        sortedKeys[smallestKey] = object[smallestKey];
        keysArray.splice(smallestIndex, 1);
        stop++;
    }
    return sortedKeys;
}

export const getSystemInfo = (systemName) => {
    return axios
        .post(addressGetSystemInfo,
            {
                token: localStorage.getItem("token"),
                system_key: systemName
            })
        .then(response => {
            return response
        })
        .catch(err => {
            return err.response;
        })
}

export const sortMagnification = (keysArray, object, len) => {
    let sortedKeys = {}, i, j, stop = 0;
    for (i = 0; i < len; i++) {
        let splitSmallesValue = keysArray[0].split(' ', 3)[2];
        let smallestIndex = 0,
            smallestKey = keysArray[0],
            smallestValue = splitSmallesValue.substring(0, splitSmallesValue.length - 1);
        for (j = 0; j < len - stop; j++) {
            let splitValue = keysArray[j].split(' ', 3)[2];
            let value = splitValue.substring(0, splitValue.length - 1);
            if (parseInt(value) < parseInt(smallestValue)) {
                smallestIndex = j;
                smallestKey = keysArray[j];
                smallestValue = value;
            }
        }
        sortedKeys[smallestKey] = object[smallestKey];
        keysArray.splice(smallestIndex, 1);
        stop++;
    }
    return sortedKeys;
}