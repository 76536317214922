import { useEffect } from "react";
import grapesjs from 'grapesjs'
import 'grapesjs/dist/css/grapes.min.css'
import 'grapesjs/dist/grapes.min.js'
import plugin from 'grapesjs-preset-newsletter'
import blocks from 'grapesjs-blocks-basic'
let editor = null
function WebBuilder() {
  useEffect(() => {
    editor = grapesjs.init({
      showDevices: false,
      showOffsets: true,
      container: '#gjs',
      height: '700px',
      width: '1150px',
      storageManager: false,
      plugins: ['gjs-preset-newsletter', blocks],
      pluginsOpts: {
        'gjs-preset-newsletter': {  
          block:{
            "text":{}
          }
        }
      }
    })
    editor.on('load', function() {
      let styleManager = editor.StyleManager;
      let fontProperty = styleManager.getProperty('typography', 'font-family');
      let list = fontProperty.get('options');
      var newFont = { id: 'Roboto, Arial, sans-serif', label: 'Roboto' };
      list.push(newFont);
      fontProperty.set('defaults', `Roboto, Arial, sans-serif`);
      styleManager.render(); 
    });
  }, [])


  return (
    <div>
    <div id="gjs"></div>
    </div>
  );
}
export { WebBuilder, editor };