import React from "react";
import { Grid, CardHeader, Tab, Tabs, Container } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import Button from "@material-ui/core/Button";
import Avatar from '@material-ui/core/Avatar';
import applicationLogo from "../../../design/zeiss-logo-tagline_rgb.png"
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import exampleImage from '../../../design/exampleNewSystem.png';
import { uploadFiles } from "../controller/NewSystemAction";
import { WebBuilder, editor } from "../../tools/WebBuilder";
import Tooltip from "@material-ui/core/Tooltip";
import Snackbar from "@material-ui/core/Snackbar";
import FileCopyRounded from '@material-ui/icons/FileCopyRounded';
import DeleteRounded from '@material-ui/icons/DeleteRounded';
import CloudUploadRounded from '@material-ui/icons/CloudUploadRounded';
import { handleMagneticCsvInputChange, handleAcousticCsvInputChange, fetchVideoList, handleSeismicCsvInputChange, handleZipInputChange, goToAdminPanel, toggleLoadingScreenDisplay, uploadVideo, deleteVideo } from "../../tools/SystemManagement";
class AdminPanelNewSystemContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedCsvMagnetic: "",
            selectedCsvAcoustic: "",
            selectedCsvSeismic: "",
            selectedZip: "",
            errorMessage: "",
            inputKey: Date.now(),
            loadingScreen: false,
            selectedTab: 0,
            editorHtml: null,
            editorJson: null,
            videoLinks: [],
            uploadingOrDeletingVideo: false,
            usingAzureStorage: false
        };
        
        this.handleUploadFiles = this.handleUploadFiles.bind(this);
    }
    handleUploadFiles = async (csvMagnetic, csvAcoustic, csvSeismic, zip) => {
        this.state = toggleLoadingScreenDisplay(this, true);

        //we only include the editor data if it's changed from the default format
        if (editor.getHtml() !== "<body></body>") {
            await this.setState({ editorHtml: editor.runCommand('gjs-get-inlined-html'), editorJson: editor.getProjectData() })
        }
        let response = await uploadFiles(csvMagnetic, csvAcoustic, csvSeismic, zip, this.state.editorHtml, this.state.editorJson);
        this.state = toggleLoadingScreenDisplay(this, false);
        const isString = typeof response.data === "string" || response.data instanceof String;
        if (!isString) {
            if ('redirect' in response.data) {
                this.props.history.push('/login')
            }
            let problemsFound = "";
            for (const [key, value] of Object.entries(response.data)) {
                problemsFound += (key + ":\n")
                let index;
                for (index = 0; index < Object.values(value).length; index++) {
                    problemsFound += ("- " + Object.values(value)[index] + '\n');
                }
            }
            this.setState({
                errorMessage: problemsFound,
                selectedCsvAcoustic: "",
                selectedCsvMagnetic: "",
                selectedCsvSeismic: "",
                selectedZip: "",
                inputKey: Date.now()
            })
        }
        else if (response.data === "The new system was added successfully!")
            goToAdminPanel(this);
        else this.setState({
            errorMessage: response['data'],
            inputKey: Date.now(),
            selectedCsvAcoustic: "",
            selectedCsvMagnetic: "",
            selectedCsvSeismic: "",
            selectedZip: ""
        });

    }
    componentDidMount() {
        const azureStorage = localStorage.getItem('usingAzureStorage');
        this.setState({usingAzureStorage: azureStorage});
        this.populateVideoLinks();
    }


    populateVideoLinks = async () => {
        let response = await fetchVideoList();
        if (response.status !== 200)
            return;
        let videoLinks = [];
        response.data.videos.forEach(element => {
            videoLinks.push(
                <Grid item style={{ border: '1px solid', borderRadius: '5px', marginBottom: "5px", marginTop: "5px" }} container direction="row">
                    <Grid item xs={11}>
                        <Typography noWrap style={{ marginTop: 8, marginLeft: 5 }}>
                            {element}
                        </Typography>

                    </Grid>
                    <Grid item container direction="row" xs={1} style={{ padding: 0, paddingRight: 10 }}>
                        <Grid item xs={6}>
                            <Button onClick={() => {
                                navigator.clipboard.writeText(element);
                                this.setState({ snackBarOpen: true });
                            }}>
                                <Tooltip title="Copy video link">
                                    <FileCopyRounded />
                                </Tooltip>
                            </Button>
                        </Grid>
                        <Grid item xs={6}>
                            <Button style={{ marginRight: 0 }}>
                                <Tooltip title="Delete video">
                                    <DeleteRounded style={{ color: "red" }} onClick={() => { this.handleDeleteVideo(element); }} />
                                </Tooltip>
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            )
        }
        );

        this.setState({ "videoLinks": videoLinks })
    }

    handleTabChange = (event, value) => {
        this.setState({ selectedTab: value });
    }

    editorChange() {
        this.setState({ moreInfoChanged: true })
    }

    handleMouseEnterUpload = () => {
        this.setState({ uploadMouseHover: true });
    }
    handleMouseLeaveUpload = () => {
        this.setState({ uploadMouseHover: false });
    }
    handleUploadVideo = async (event) => {
        this.setState({ uploadingOrDeletingVideo: true, errorMessage: "" })
        let response = await uploadVideo(event.target.files[0])
        if (response.data.redirect !== undefined)
            this.props.history.push('/login')

        if (response.status === 200) {
            this.populateVideoLinks();
        }
        else
            this.setState({ errorMessage: response.data })
        this.setState({ uploadingOrDeletingVideo: false })
    }
    handleDeleteVideo = async (videoName) => {
        this.setState({ uploadingOrDeletingVideo: true, errorMessage: "" })
        let split = videoName.split("/");
        videoName = split[split.length - 1];
        let response = await deleteVideo(videoName)
        if (response.status === 200) {
            this.populateVideoLinks();
        }
        this.setState({ uploadingOrDeletingVideo: false })
    }
    render() {
        return <>
            <Container>
                {this.state.loadingScreen ? 
                <div>
                    <div
                    style={{
                        top: "50%",
                        left: "50%",
                        marginTop: "-50px",
                        marginLeft: "-100px",
                        position: "absolute",
                        width: "200px",
                        height: "200px"
                    }}
                >

                    <img alt="App logo" src={applicationLogo} style={{
                        width: "80%",
                        height: "80%",
                        position: "absolute",
                        left: "10%",
                        top: "5%"
                    }} />

                    <CircularProgress style={{
                        position: "absolute",
                        width: "100%",
                        height: "100%"
                    }} />
                    </div>
                    <div style={{
                        top: "75%",
                        left: "50%",
                        marginLeft: "-100px",
                        position: "absolute",
                        width: "200px",
                        height: "200px"
                    }}>
                    {this.state.usingAzureStorage &&
                    <Typography style={{textAlign:"center"}}>
                        Uploading data to cloud. This might take a few minutes...
                    </Typography>
                    }
                </div>
                </div> :
                    <Grid>
                        <Grid className="page_header">
                            <div className="page">
                                <Grid container direction="row" justifyContent="center" alignItems="center">
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <CardHeader
                                            avatar={
                                                <Avatar variant={"square"} aria-label="ripple-simulator" src={applicationLogo}
                                                    style={{
                                                        width: "80px",
                                                        height: "80px",
                                                    }
                                                    } />
                                            }
                                            titleTypographyProps={{ variant: "h4", color: 'textPrimary' }}
                                            title={"Ripple Simulator - System Creator"}
                                        />
                                    </Grid>
                                </Grid>
                            </div>
                        </Grid>
                        <Grid container direction="row">
                            <Grid item container sm={12} md={7} direction="column">
                                <Grid item container direction="column" spacing={6}>
                                    <Grid item>
                                        <Typography style={{ fontSize: 30 }}>
                                            CREATING A NEW SYSTEM
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <Typography>
                                            <b>
                                                Images
                                            </b>
                                        </Typography>
                                        <form>
                                            <input type="file" key={this.state.inputKey} onChange={event => this.state = handleZipInputChange(event, this)} accept=".zip" />
                                        </form>
                                    </Grid>
                                    <Grid item container spacing={2}>
                                        <Grid item>
                                            <Button variant="outlined" onClick={this.handleUploadFiles.bind(this, this.state.selectedCsvMagnetic, this.state.selectedCsvAcoustic, this.state.selectedCsvSeismic, this.state.selectedZip)}
                                                disabled={(this.state.selectedCsvAcoustic === "" && this.state.selectedCsvMagnetic === "" && this.state.selectedCsvSeismic === "")
                                                    || this.state.selectedZip === ""}>
                                                Setup System
                                            </Button>
                                        </Grid>
                                        <Grid item>
                                            <Button variant="outlined" onClick={goToAdminPanel.bind(this, this)}>
                                                Back to admin panel
                                            </Button>
                                        </Grid>
                                    </Grid>
                                    <Grid item>
                                        <Typography style={{ color: "red", marginTop: "20px", whiteSpace: 'pre-line' }}>
                                            {this.state.errorMessage}
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Grid item style={{ marginBottom: "100px" }}>
                                    <Tabs variant="scrollable" scrollButtons="auto" indicatorColor="primary" value={this.state.selectedTab} onChange={this.handleTabChange}>
                                        <Tab label={(<Typography style={{ fontSize: "13px" }}>Magnetic distortion</Typography>)} />
                                        <Tab label={(<Typography style={{ fontSize: "13px" }}>Acoustic distortion</Typography>)} />
                                        <Tab label={(<Typography style={{ fontSize: "13px" }}>Seismic distortion</Typography>)} />
                                        <Tab label={(<Typography style={{ fontSize: "13px" }}>More information</Typography>)} />
                                    </Tabs>
                                    <div style={{ display: this.state.selectedTab === 0 ? "block" : "none" }}>
                                        <Typography style={{ marginTop: 50 }}>
                                            <b>
                                                CSV table for magnetic sensitivity
                                            </b>
                                        </Typography>
                                        <form>
                                            <input type="file" name="file" key={this.state.inputKey} onChange={event => this.state = handleMagneticCsvInputChange(event, this)} accept=".csv" />
                                        </form>
                                    </div>
                                    <div style={{ display: this.state.selectedTab === 1 ? "block" : "none" }}>
                                        <Typography style={{ marginTop: 50 }}>
                                            <b>
                                                CSV table for acoustic sensitivity
                                            </b>
                                        </Typography>
                                        <form>
                                            <input type="file" name="file" key={this.state.inputKey} onChange={event => this.state = handleAcousticCsvInputChange(event, this)} accept=".csv" />
                                        </form>
                                    </div>
                                    <div style={{ display: this.state.selectedTab === 2 ? "block" : "none" }}>
                                        <Typography style={{ marginTop: 50 }}>
                                            <b>
                                                CSV table for seismic sensitivity
                                            </b>
                                        </Typography>
                                        <form>
                                            <input type="file" name="file" key={this.state.inputKey} onChange={event => this.state = handleSeismicCsvInputChange(event, this)} accept=".csv" />
                                        </form>
                                    </div>
                                    <div style={{ display: this.state.selectedTab === 3 ? "block" : "none", width: "100%" }}>
                                    {this.state.uploadingOrDeletingVideo === true ? (
                                            <CircularProgress />) :
                                            (<Grid>
                                                <Grid item style={{ marginTop: "10px", marginBottom: "10px", width: 150 }}>
                                                    <div onMouseEnter={this.handleMouseEnterUpload}
                                                        onMouseLeave={this.handleMouseLeaveUpload}>
                                                        <label htmlFor="formId">
                                                            <input name="" onChange={event => this.handleUploadVideo(event)} accept=".mp4" key={this.state.uploadedVideoKey}
                                                                type="file" id="formId" hidden />

                                                            <Grid style={{ borderRadius: '5px', padding: 10, paddingTop: 15, backgroundColor: this.state.uploadMouseHover ? "#141e8c" : "#3f51b5" }} container direction="row">
                                                                <Grid item>
                                                                    <CloudUploadRounded style={{ color: "white" }} />
                                                                </Grid>
                                                                <Grid item>
                                                                    <Typography style={{ marginLeft: 5, color: "white" }}>
                                                                        Upload video
                                                                    </Typography>
                                                                </Grid>

                                                            </Grid>

                                                            <Snackbar
                                                                open={this.state.snackBarOpen}
                                                                autoHideDuration={2000}
                                                                onClose={() => this.setState({ snackBarOpen: false })}
                                                                message="Video link copied"
                                                            >
                                                            </Snackbar>
                                                        </label>
                                                    </div>
                                                </Grid>
                                                {
                                                    this.state.videoLinks.length > 0 &&
                                                    (<Grid item style={{ maxHeight: "250px", overflowY: "scroll", overflowX: "hidden", border: '1px solid', borderRadius: '10px', padding: 10, width: "1150px", marginTop: "20px", marginBottom: "20px" }}>
                                                        <Grid container>
                                                            {this.state.videoLinks}
                                                        </Grid>
                                                    </Grid>)}
                                            </Grid>
                                            )}
                                        <WebBuilder />
                                    </div>
                                </Grid>
                            </Grid>
                            {this.state.selectedTab !== 3 && (
                                <Grid item container sm={12} md={5}>
                                    <Grid item xs={12}>
                                        <Typography>
                                            <b>1.</b> SEM images should be collected in a specific format: <br />
                                            Image Size: 4096, 3072 <br />
                                            Ref Mag: 4x5 Polaroid <br />
                                            Image settings: Grey or RGB <br />
                                            No annotations on the images<br />
                                            As example:<br />
                                            Magnification list:<br />
                                            FEG 10K, 50K and 300K (Mag. range is then from 10K to 1000K)<br />
                                            Magnification list:<br />
                                            FEG 1K, 10K and 50K (Mag. Range is then from 1K to 200K)<br />
                                            <br />
                                            <b>2.</b> Each image should be labelled in the following order:<br />
                                            EHT: 5kv (no spaces, k lower case and V upper case)<br />
                                            Image size:  4096 (only the X size shown)<br />
                                            Mag: 10K (Polaroid 4x5 mag only, no spaces and upper case K)<br />
                                            Image pixel size: 0.053nm read off the image pixel size. Not the one from the tiff file (no commas and only in nm) <br />
                                            Example: 5kV 4092 1K 27.91nm.tif<br />
                                            The collection of the images must be Zipped to a file<br />
                                            <br />
                                            <b>3.</b> The .csv file for the SEM contains the details for: the name of the system, the EHT, the WD and the deflection expected.<br />
                                            The data from this table will allow the selection of the System, Beam energy and Working Distance that will be displayed<br />
                                            <br />
                                            <b>4.</b>No special characters allowed in the name of the system. Spaces allowed<br />
                                            Beam int: 1kV (No spaces, lower case k and UPPER CASE V, only 1 decimal with a dot)<br />
                                            WD: 8mm (No spaces, lower case m, only 1 decimal with a dot)<br />
                                            nm/mg: 0.518683 (can have 6 ore more decimals with a dot)<br />
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography> Example:</Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <img style={{ width: "100%", height: "auto" }} src={exampleImage} />
                                    </Grid>
                                </Grid>)}
                        </Grid>

                    </Grid>
                }
            </Container>
        </>;
    }
}

export default withRouter(AdminPanelNewSystemContainer);
