import React from 'react';
import ReactDOM from "react-dom"
import { Router, Switch, Redirect } from "react-router-dom";
import SignIn from "./signin/container/SignInContainer";
import { MyRoute } from "./functions/RoutePath";
import history from './functions/history';
import { PrivateRoute } from "./functions/PrivateRoute";
import InputContainer from "./inputpage/container/InputContainer";
import MagneticContainer from "./output/magnetic/container/MagneticContainer";
import AcousticContainer from "./output/acoustic/container/AcousticContainer";
import SeismicContainer from "./output/seismic/container/SeismicContainer";
import './App.css';
import AdminPanelContainer from './admin/adminpanel/container/AdminPanelContainer';
import NewSystemContainer from './admin/newsystem/container/NewSystemContainer';
import EditSystemContainer from './admin/editsystem/container/EditSystemContainer';


async function init() {
    const routing = (
        <Router history={history}>
            <Switch>
                <MyRoute path={"/login"} component={SignIn} />
                {/*<MyRoute path={"/register"} component={Register}/>*/}
                <PrivateRoute path={"/inputs"} component={InputContainer} />
                <PrivateRoute path={"/output_magnetic"} ><MagneticContainer /></PrivateRoute>
                <PrivateRoute path={"/output_acoustic"} ><AcousticContainer /></PrivateRoute>
                <PrivateRoute path={"/output_seismic"} ><SeismicContainer /></PrivateRoute>
                <PrivateRoute path={"/admin"} ><AdminPanelContainer /></PrivateRoute>
                <PrivateRoute path={"/new_system"}><NewSystemContainer /></PrivateRoute>
                <PrivateRoute path={"/edit_system"}><EditSystemContainer /></PrivateRoute>
                <Redirect to="/login" />
            </Switch>
        </Router>
    );
    ReactDOM.render(routing, document.getElementById("root"));
}

init();